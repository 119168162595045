import { msg } from '@lingui/macro';
import { md } from 'content/types';

export default {
  pageTitles: {
    welcome: msg`myVoyage Personalized Enrollment Guidance: Sign In`,
  },
  screen: {
    results: {
      title: ({ count }: { count: number }) =>
        msg`Your result${
          count > 1 ? 's' : ''
        }: ${count} personalized options to guide your decision`,

      intro:
        md(msg`These benefit combinations were matched to you based on the information you provided.
      Explore and compare your personalized options below, or customize your own.
${'_lf'}
**Remember, you cannot enroll through this tool.**`),
    },
  },
};
