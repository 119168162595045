export default {
  DEPLOY_ENV: 'production',
  SENTRY: {
    ORG: 'savvi',
    PROJECT: 'myhealthmoney-benefits',
    DSN: 'https://19d784e3a352446f82e3844b80e2acd5@o138561.ingest.sentry.io/5466190',
  },
  SITE: {
    LAYOUT: 'standalone',
    COLORMODE: 'light',
    ID: 'myhealthmoney',
    NAME: 'Personalized Enrollment Guidance',
    EMAIL_SUPPORT: 'myhealthmoney@savvifi.com',
    API_URL: 'https://api.myvoyageguide.com/v1',
    APP_ID: '3ade3b08-6692-4013-a251-d12b7cdbbac9',
    CLIENT_ID: '3c9a5580-9d18-44f8-8ea8-f961139da3e4',
    RECAPTCHA_SITE_KEY: '6LcUJsAUAAAAAIKR8ZePV5dlVxl-EasC5tDN8x8T',
    QASE_API_TOKEN: '',
    EXTERNAL_AUTH_ONLY: false,
    DISABLE_PRIVACY_MODAL: false,
    ENABLE_RETURNING_USER: false,
    ENABLE_DEVELOPER_TOOLS: false,
    LOCALES: ['en-US', 'es-US'],
    ENABLE_CUSTOM_BUNDLES: true,
    IS_DEMO_SITE: false
  },
  SETTINGS: {
    LOGO_ON_SUBMIT: false,
    ANALYTICS: {
      AMPLITUDE_API_KEY: '38bfd5a1c6eabaa90a9b3500c5e62fe1',
      GA4_MEASUREMENT_ID: 'G-HZGQ5YCEXB',
    },
  },
};
