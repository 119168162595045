import { Tracker, defaultPageViewPath, defaultPageViewTitle } from './tracker';

declare global {
  interface Window {
    gtag: (...params: unknown[]) => object;
  }
}

export class DevTracker implements Tracker {
  private eventConfig: { [key: string]: any } = {};

  private internalTrack({ event, ...params }: { [key: string]: any }) {
    // eslint-disable-next-line no-console
    console.log(
      event,
      [params.category, params.action, params.label, params.path]
        .filter(Boolean)
        .join(' '),
      params,
    );
  }

  config(params: { [key: string]: any }): void {
    Object.assign(this.eventConfig, params);
  }

  pageView(params: { path?: string; title?: string; [key: string]: any }) {
    this.internalTrack({
      ...this.eventConfig,
      event: 'page_view',
      path: params.path ?? defaultPageViewPath(),
      title: params.title ?? defaultPageViewTitle(),
      ...params,
    });
  }

  track({
    action,
    category,
    label,
    value,
    ...rest
  }: {
    action: string;
    category: string;
    label: string;
    value?: number;
    [key: string]: any;
  }) {
    this.internalTrack({
      ...this.eventConfig,
      event: 'event',
      category,
      action,
      label,
      value,
      ...rest,
    });
  }
}
