import { msg } from '@lingui/macro';

export default {
  welcome: msg`Personalized Enrollment Guidance: Sign In`,
  guidanceMode: msg`What Brings You Here Today?`,
  profileHousehold: msg`Your Household`,
  profileEmployment: msg`Your Employement Situation`,
  profileIncome: msg`Your Income`,
  profileMidyearPlanDetails: msg`Your Benefits`,
  healthcareAverageUsage: msg`Your Typical Healthcare Usage`,
  healthcareSpecialUsage: msg`Planned Out-of-the-Ordinary Healthcare Usage`,
  partnerBenefits: msg`Your Partner's Benefits`,
  tobaccoUsage: msg`Tobacco Usage`,
  benefitsProfile: msg`Your Health Account Balances`,
  supplementalBenefits: msg`Supplemental Benefits`,
  submit: msg`Calculating Your Plan Options`,
  results: msg`Your Personalized Options`,
  coverageLife: msg`Life Insurance`,
  coverageAdd: msg`Accidental Death and Dismemberment Insurance`,
  coverageDisability: msg`Disability Income Insurance`,
  emergencyFund: msg`Your Emergency Savings`,
  retirementAccounts: msg`Your Retirement Savings`,
  retirementAccountsPartner: msg`Your Partner's Retirement Savings`,
  retirementAccountsOther: msg`Other Retirement Accounts`,
  retirementGoals: msg`Your Retirement Goals`,
  review: msg`Calculating Your Budget Options`,
  budgeting: msg`Plan For Your Health & Wealth Goals`,
  actionPlan: msg`Your Action Plan`,
};
