// System
import React, { useCallback, useMemo } from 'react';

// Build-centric
import { SITE, SETTINGS, DEPLOY_ENV } from '../../../site.config';
import usePostMessage from 'hooks/usePostMessage';
import content from 'content/values';
import { useMaybeLingui } from 'hooks/useMaybeLingui';

//Util

// Type
export type AppContextType = {
  id: string;
  name: string;
  colorMode: 'light' | 'dark';
  deployEnv?: 'development' | 'test' | 'production';
  disablePrivacyModal?: boolean;
  externalAuthOnly?: boolean;
  layout?: 'standalone' | 'inline';
  setColorMode?: (mode: AppContextType['colorMode']) => void;
  setLayout?: (layout: AppContextType['layout']) => void;
  settings?: typeof SETTINGS;
};

// Make this test
const appIsLoadedInIframe = window.self !== window.top;

// New context, defaults
const AppContext = React.createContext<AppContextType>({} as AppContextType);

// Hook
export const useApp = () => React.useContext(AppContext);

// Provider for our UI
export const AppProvider = ({ children }: { children: React.ReactNode }) => {
  const { postMessage } = usePostMessage();
  const { _ } = useMaybeLingui();

  // Local state
  const [app, setApp] = React.useState<AppContextType>({
    name: '', // Set below
    deployEnv: DEPLOY_ENV as AppContextType['deployEnv'],
    layout: (appIsLoadedInIframe
      ? 'inline'
      : SITE.LAYOUT) as AppContextType['layout'],
    id: SITE.ID as AppContextType['id'],
    colorMode: SITE.COLORMODE as AppContextType['colorMode'],
    externalAuthOnly: SITE.EXTERNAL_AUTH_ONLY === true,
    disablePrivacyModal: SITE.DISABLE_PRIVACY_MODAL === true,
    settings: SETTINGS,
  });

  // Util
  const setColorMode = useCallback(
    (mode: AppContextType['colorMode']) =>
      setApp((a: AppContextType) => {
        a.colorMode = mode;
        return { ...a };
      }),
    [],
  );

  // Util
  const setLayout = useCallback(
    (layout: AppContextType['layout']) =>
      setApp((a: AppContextType) => {
        a.layout = layout;
        return { ...a };
      }),
    [],
  );

  // Update this as needed
  const value = useMemo(
    () => ({ ...app, name: _(content.product.name), setColorMode, setLayout }),
    [app, setColorMode, setLayout, _],
  );

  // UI
  const appHeight = React.useRef(0);

  // For inline, handle postMessage
  React.useEffect(() => {
    if (app.layout !== 'inline') return;

    const monitorAppHeight = () =>
      setInterval(() => {
        const appWrapper = document.getElementById('root');
        const newHeight = appWrapper?.scrollHeight || 0;
        if (appHeight?.current !== newHeight) {
          appHeight.current = newHeight;
          postMessage('contentHeight', newHeight);
        }
      }, 100);

    const interval = monitorAppHeight();

    return () => {
      clearInterval(interval);
    };
  }, [app.layout, postMessage]);

  // Render
  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

// Make it clear
export default AppContext;
