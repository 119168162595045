import {
  Box,
  Breakpoint,
  Dialog as DialogMui,
  DialogActions,
  DialogContent,
  SxProps,
  Theme,
} from '@mui/material';
import classNames from 'classnames';
import * as React from 'react';

import { omit } from 'lodash/fp';
import { PropsWithChildren, ReactElement, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { isFragment } from 'react-is';
import { Button } from '../Button';
import { SITE } from '../../../../site.config';
import DialogHeader, { DialogHeaderProps } from './DialogHeader';
import { useTrackEvent } from 'hooks/useTrackEvent';
import { conditionalReverse } from 'helpers/common';
import usePostMessage from 'hooks/usePostMessage';
import { useLingui } from '@lingui/react';
import { msg } from '@lingui/macro';

type DialogVariant = 'info' | 'acknowledge' | 'alert';

export type BasicProps = DialogHeaderProps & {
  header?: ReactElement<DialogHeaderProps>;
  actions?: ReactElement;
  testID?: string;
  trackingID?: string;
  variant: DialogVariant;
  fullWidth?: boolean;
  fullWidthContent?: boolean;
  simple?: boolean;
  maxWidth?: Breakpoint;
  topAligned?: boolean;
  contentSx?: SxProps<Theme>;
  scroll?: 'body' | 'paper' | undefined;
};

// info = no buttons
export type InfoProps = BasicProps & {
  variant: 'info';
};

// acknowledge = 1 button (confirm)
export type AcknowledgeProps = BasicProps & {
  variant: 'acknowledge';
  confirmButtonLabel?: string;
  confirmDisabled?: boolean;
  onConfirm?(): void;
  preferCancel?: boolean;
};

// alert = 2 buttons (confirm, cancel)
export type AlertProps = BasicProps & {
  variant: 'alert';
  confirmButtonLabel?: string;
  confirmDisabled?: boolean;
  onConfirm?(): void;
  cancelButtonLabel?: string;
  onCancel?(): void;
  preferCancel?: boolean;
};

export type DialogProps = InfoProps | AcknowledgeProps | AlertProps;

const Dialog: React.FC<PropsWithChildren<DialogProps>> = (props) => {
  const {
    header,
    description,
    actions,
    ariaId,
    open,
    onClose,
    variant,
    // eslint-disable-next-line lingui/no-unlocalized-strings
    testID = 'InfoModal',
    trackingID,
    fullWidth,
    fullWidthContent,
    simple,
    maxWidth,
    children,
    contentSx,
    topAligned,
    scroll,
    persistent,
  } = props;

  const ariaIdResolved = useMemo(() => ariaId || uuidv4(), [ariaId]);

  const headerComponent = header || (
    <DialogHeader {...omit('children', props)} ariaId={ariaIdResolved} />
  );

  const { _ } = useLingui();

  const { tracker } = useTrackEvent();

  const { postMessage } = usePostMessage();

  React.useEffect(() => {
    if (open && trackingID) {
      tracker?.track({
        category: 'modal',
        action: 'show',
        label: trackingID,
      });
    }
    if (open && SITE.LAYOUT === 'inline') {
      postMessage('navigate', true);
    }
  }, [open, tracker, trackingID, postMessage]);

  // When persistent, we can't close it
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const doClose = persistent ? () => {} : onClose;

  return (
    <DialogMui
      PaperProps={{ 'data-testid': testID }}
      scroll={scroll}
      open={open}
      onClose={() => {
        if (!persistent && trackingID) {
          tracker?.track({
            category: 'modal',
            action: 'close',
            label: trackingID,
          });
        }

        doClose();
      }}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      className={classNames({
        topAligned: SITE.LAYOUT === 'inline' ? true : topAligned,
        dialogHasButtons: actions || variant !== 'info',
        dialogFullWidthContent: fullWidthContent,
        dialogSimple: simple,
      })}
      aria-labelledby={`${ariaIdResolved}-dialog-title`}
      aria-describedby={
        description ? `${ariaIdResolved}-dialog-description` : undefined
      }>
      {headerComponent}
      <DialogContent sx={contentSx} data-testid={`${testID}-content`}>
        <Box className="dialogContentWrapper">{children}</Box>
      </DialogContent>
      {actions && (
        <DialogActions
          sx={{
            justifyContent: {
              xs: 'flex-start',
              sm:
                isFragment(actions) &&
                actions.props.children.filter((x: any) => x).length > 1
                  ? 'space-between'
                  : 'flex-end',
            },
          }}>
          {actions}
        </DialogActions>
      )}
      {(variant === 'alert' || variant === 'acknowledge') && (
        <DialogActions
          sx={{
            justifyContent: {
              xs: 'flex-start',
              sm:
                variant === 'alert' && (!persistent || props.onCancel)
                  ? 'space-between'
                  : 'flex-end',
            },
          }}>
          <>
            {conditionalReverse(!!props.preferCancel, [
              variant === 'alert' && (!persistent || props.onCancel) && (
                <Button
                  key="cancelButton"
                  className="dialogActionsCancel"
                  onClick={props.onCancel || doClose}
                  variant={props.preferCancel ? 'contained' : 'text'}
                  testID={testID + '-cancel'}
                  trackingID={trackingID + '-cancel'}>
                  {props.cancelButtonLabel || _(msg`Cancel`)}
                </Button>
              ),
              (!persistent || props.onConfirm) && (
                <Button
                  key="confirmButton"
                  onClick={props.onConfirm || doClose}
                  disabled={props.confirmDisabled}
                  variant={props.preferCancel ? 'text' : 'contained'}
                  testID={testID + '-confirm'}
                  trackingID={trackingID + '-confirm'}>
                  {props.confirmButtonLabel || _(msg`Ok`)}
                </Button>
              ),
            ])}
          </>
        </DialogActions>
      )}
    </DialogMui>
  );
};

export default Dialog;
