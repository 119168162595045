import { Months, Role } from 'interfaces/common';
import { Person } from 'interfaces/household';
import { NavigationStepConfig } from 'interfaces/navigation';
import * as types from 'selectsmart-api/types';
import { RouteNames } from '../RouteNames';
import { RootState } from '../components/store/Store';
import {
  DataSource,
  Dictionary,
  PayFrequency,
  SelectSmartData,
  SerializedStore,
} from '../interfaces/common';
import { DEFAULT_AGE, DEFAULT_AGE_DEPENDENT } from './constants';

export const isNil = (x: any) => x == null; // Intentional == to test for null or undefined

export function transformArrayToObject<T extends { id: string }>(
  array: T[],
): Dictionary<T> {
  return Object.assign(
    {},
    ...array.map((item, index) => ({
      [typeof item.id === 'string' && item.id.length ? item.id : index]: item,
    })),
  );
}

export const formatBirthDate = (bday: string) => {
  if (!bday) {
    return '';
  } else if (bday.includes('-')) {
    const newbday = bday.split('-');
    return `${newbday[1]}/${newbday[2]}/${newbday[0]}`;
  } else {
    return bday;
  }
};

export function serializeStoreToStorage(state: RootState): SerializedStore {
  const { auth, retirement, settings, apiState } = state;

  return {
    household: apiState.household,
    benefits: apiState.benefits,
    auth,
    emergency_fund_goal: apiState.goals.emergencyfund,
    retirement_goal: apiState.goals.retirement,
    retirement,
    budgeting: apiState.budgeting,
    settings,
    people: apiState.people,
    bundles: apiState.bundles,
    incomes: apiState.incomes,
    tobaccoUse: apiState.tobaccoUse,
    healthcare: apiState.healthcare,
    benefitUses: apiState.benefitUses,
  };
}

export const removeKeys = (obj: any, keys: string[]): any =>
  obj !== Object(obj)
    ? obj
    : Array.isArray(obj)
    ? obj.map((item) => removeKeys(item, keys))
    : Object.keys(obj)
        .filter((k) => !keys.includes(k))
        .reduce(
          (acc, x) => Object.assign(acc, { [x]: removeKeys(obj[x], keys) }),
          {},
        );

export function normalizeSmartSelectDataFromServer(
  data: SelectSmartData,
  parsedData?: SerializedStore,
): SerializedStore {
  const peopleAges: any = {};
  Object.values(data.people).forEach((p: Person) => {
    peopleAges[p.id] = p.birthdate
      ? getAge(p.birthdate)
      : p.role === Role.DEPENDENT
      ? DEFAULT_AGE_DEPENDENT
      : DEFAULT_AGE;
  });
  const clientId =
    Object.values(data.people).find((p) => p.role === Role.CLIENT)?.id || '';
  const spouseId =
    Object.values(data.people).find((p) => p.role === Role.SPOUSE)?.id || '';
  const retirement = {};
  const pension = {};
  let ira = {};
  let hasSpouseEsrp = false;
  Object.values(data.accounts).forEach((el) => {
    if (el.type === 'esrp') {
      if (el.person_id !== clientId) {
        if (
          // @ts-ignore
          el.balance_aftertax !== 0 ||
          // @ts-ignore
          el.balance_pretax !== 0 ||
          // @ts-ignore
          el.balance_roth !== 0
        ) {
          hasSpouseEsrp = true;
        }
      }
      // @ts-ignore
      if (!retirement[el.person_id]) {
        // @ts-ignore
        retirement[el.person_id] = {};
      }
      // @ts-ignore
      retirement[el.person_id][el.id] = {
        ...el,
        // @ts-ignore
        match_tiers: el.match_tiers ? el.match_tiers : [],
      };
    } else if (el.type === 'pension') {
      // @ts-ignore
      pension[el.person_id] = el;
    } else if (el.type === 'ira') {
      ira = el;
    }
  });
  const {
    employer,
    people,
    incomes,
    healthcare,
    tobacco_use,
    accounts,
    plans,
    benefits,
    benefit_uses,
    bundles,
    goals,
    nextdollar_id,
    ...household
  } = data;
  return {
    healthcare: healthcare,
    // @ts-ignore
    household: {
      ...household,
      initialized: true,
      ages: peopleAges,
      allIds: Object.keys(people).map((id) => id) ?? [],
      clientId,
      spouseId,
    },
    bundles: {
      ...bundles,
      submitted: false,
    },
    // maybe this should be benefits instead of bundles.benefits?
    benefits: bundles?.benefits || {},
    retirement: {
      retirement,
      pension,
      // @ts-ignore
      ira,
      initialized: true,
      addSpouseRetirement: hasSpouseEsrp,
      addIraAccounts:
        // @ts-ignore
        (ira?.balance_pretax != null && ira.balance_pretax > 0) ||
        // @ts-ignore
        (ira?.balance_roth != null && ira.balance_roth > 0),
    },
    retirement_goal: goals?.retirement,
    emergency_fund_goal: goals?.emergencyfund,
    budgeting: {
      // @ts-ignore
      id: nextdollar_id,
      adjusted_budget: parsedData?.budgeting?.adjusted_budget || 0,
    },
    // @ts-ignore
    settings: parsedData?.settings || {},
    employer: employer,
    people: people,
    incomes: incomes,
    tobaccoUse: tobacco_use,
    benefitUses: benefit_uses,
  };
}

export function getStepPercentComplete(
  userJourney: NavigationStepConfig[],
  routeName: any,
  formSteps: any,
) {
  const currentStep = userJourney.find(
    (step) => step.route === routeName,
  )?.name;
  const availablePagesInNavigationStep = userJourney.filter(
    (step) => step.name === currentStep && formSteps.includes(step.route),
  );
  //  +1 to offset 0 index
  const currentStepNumber =
    availablePagesInNavigationStep.findIndex(
      (page) => page.route === routeName,
    ) + 1;
  const percentComplete = Math.round(
    (currentStepNumber * 100) / availablePagesInNavigationStep?.length,
  );
  return percentComplete;
}

export const isMidYearJourney = (state: RootState): boolean =>
  state?.apiState?.household?.guidance_mode === 'midyear-update';

export const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
});

export const currencyFormatterRounded = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
});

export const getCurrencyFormatDecimalPlaces = (digits: number) =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: digits,
    minimumFractionDigits: digits,
  });

export const formatCurrency = (
  amount: number | null,
  rounded?: boolean,
  digits = 0,
): string => {
  return amount === null
    ? 'N/A'
    : rounded
    ? digits > 0
      ? getCurrencyFormatDecimalPlaces(digits).format(amount)
      : currencyFormatterRounded.format(amount)
    : getCurrencyFormatDecimalPlaces(digits).format(amount);
};

export const roundToNumberOfDecimals = (num: number, places: number) => {
  const x = Math.pow(10, places);
  return Math.round(num * x) / x;
};

export function getAgeInMonths(
  birth_date: string | Date | undefined,
  asOfDate: Date | null = null,
) {
  if (birth_date == null || birth_date === '') {
    return 0;
  }

  // convert ISO formatted string date to Date object
  let birthDate: Date;
  if (typeof birth_date === 'string') {
    const ar = birth_date.split('-');
    birthDate = new Date(
      parseInt(ar[0]),
      parseInt(ar[1]) - 1, // funky JavaScript monthIndex
      parseInt(ar[2]),
    );
  } else {
    birthDate = birth_date;
  }

  // establish default asOfDate to today
  if (!asOfDate) {
    asOfDate = new Date();
  }

  const monthsOld =
    +(12 * (asOfDate.getFullYear() - birthDate.getFullYear())) + // twelve months per year
    (asOfDate.getMonth() - birthDate.getMonth()) + // add or subtract month of year difference
    (birthDate.getDate() <= asOfDate.getDate() ? 1 : 0); // add one more month if birthday happened in final month

  return monthsOld;
}

export function getAge(
  birth_date: string | Date | undefined | null,
  asOfDate: Date | undefined = undefined,
) {
  if (birth_date == null || birth_date === '') {
    return 0;
  }
  return Math.floor(getAgeInMonths(birth_date, asOfDate) / 12);
}

export const getMonth = (birthdate: string) =>
  Object.values(Months)[parseInt(birthdate?.split('-')[1]) - 1];

export const getMonthIndex = (birthdate: string) =>
  parseInt(birthdate?.split('-')[1]) - 1;

export const getYear = (birthdate: string) => birthdate?.split('-')[0] || '';

export const percent = (value: number, rounded?: boolean) =>
  rounded
    ? `${Math.round(value).toFixed().toLocaleString()}%`
    : rounded === false
    ? `${value.toLocaleString()}%`
    : value < 10
    ? value < 0.1
      ? `${(Math.round(value * 100) / 100).toLocaleString()}%`
      : `${(Math.round(value * 10) / 10).toLocaleString()}%`
    : `${Math.round(value).toLocaleString()}%`;

export const money = (amount: number, rounded?: boolean, digits = 0) =>
  formatCurrency(amount, rounded != null ? rounded : amount >= 100, digits);

export const isInputDisabled = (obj: any) =>
  (obj?.source &&
    obj.source !== DataSource.USER &&
    obj.source !== DataSource.DEFAULT) ||
  obj.source_id !== null;

const excludedRoutes = [
  RouteNames.Welcome,
  RouteNames.SignIn,
  RouteNames.SignUp,
  RouteNames.Go,
  RouteNames.EngineData,
  RouteNames.Terms,
  RouteNames.BundleSummary,
];

export const routeOrDefault = (
  route: RouteNames | null,
  defaultStep: RouteNames,
  userJourney: NavigationStepConfig[],
) => {
  if (!route) {
    return defaultStep;
  }
  const isValidRoute = !!Object.values(RouteNames).find((r) => r === route);
  const existsInJourney = !!userJourney.find((s) => s.route === route);
  if (
    !route ||
    excludedRoutes.includes(route) ||
    !isValidRoute ||
    !existsInJourney
  ) {
    return defaultStep;
  } else {
    return route;
  }
};

export const isExcludedRoute = (route: string | undefined) =>
  excludedRoutes.includes(route as RouteNames);

export const capitalizeFirstLetter = (st: string) => {
  return `${st.charAt(0).toUpperCase()}${st.slice(1)}`;
};

export const cap = capitalizeFirstLetter;

export const calculateValueForPeriod = (
  period: PayFrequency,
  value: number,
) => {
  switch (period) {
    case PayFrequency.WEEKLY:
      return value / 52;
    case PayFrequency.BIWEEKLY:
      return value / 26;
    case PayFrequency.MONTHLY:
      return value / 12;
    case PayFrequency.SEMIMONTHLY:
      return value / 24;
    default:
      return value;
  }
};

export const replaceLast = (st: string, what: string, forWhat: string) => {
  if (!(st.match(`/${what}/g`) || []).length) {
    return st;
  }
  const pcs = st.split(what);
  const lastPc = pcs.pop();
  return pcs.join(what) + forWhat + lastPc;
};

export function formatDateString(date: Date) {
  if (date.getFullYear() == new Date().getFullYear()) {
    return date.toLocaleDateString('en-US', {
      day: 'numeric',
      month: 'long',
    });
  } else {
    return date.toLocaleDateString('en-US', {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    });
  }
}

export function planYearStartDate(employer: any) {
  const start_date = new Date(employer.plan_year, employer.start_month - 1, 1);
  return start_date;
}

export function planYearEndDate(employer: any) {
  const end_date = new Date(
    new Date(employer.plan_year + 1, employer.start_month - 1, 1).setDate(
      new Date(employer.plan_year + 1, employer.start_month - 1, 1).getDate() -
        1,
    ),
  );
  return end_date;
}

export function planCalendarYearEndDate(employer: types.EmployerBenefitsYear) {
  const end_date = new Date(employer.plan_year + 1, 11, 31);
  return end_date;
}

export function planYearStartDateString(employer: any) {
  const start_date = planYearStartDate(employer);
  return formatDateString(start_date);
}

export function planYearEndDateString(employer: any) {
  const end_date = new Date(
    new Date(employer.plan_year + 1, employer.start_month - 1, 1).setDate(
      new Date(employer.plan_year + 1, employer.start_month - 1, 1).getDate() -
        1,
    ),
  );
  return formatDateString(end_date);
}

export function planYearStartMonthString(employer: any) {
  const start_date = new Date(employer.plan_year, employer.start_month - 1, 1);
  const startMonthString = start_date.toLocaleDateString('en-US', {
    month: 'short',
    year: 'numeric',
  });
  return startMonthString;
}

export function planYearEndMonthString(employer: any) {
  const end_date = new Date(
    new Date(employer.plan_year + 1, employer.start_month - 1, 1).setDate(
      new Date(employer.plan_year + 1, employer.start_month - 1, 1).getDate() -
        1,
    ),
  );
  const endMonthString = end_date.toLocaleDateString('en-US', {
    month: 'short',
    year: 'numeric',
  });
  return endMonthString;
}

const identity = (x: any) => x;

export const valueOrNA = (v: any, fn?: (v: any) => any) =>
  !isNil(v) ? (fn || identity)(v) : 'NA';

export const getReactNodeText: any = (node: any) => {
  if (['string', 'number'].includes(typeof node)) return node;
  if (node instanceof Array) return node.map(getReactNodeText).join('');
  if (typeof node === 'object' && node)
    return getReactNodeText(node.props.children);
};

export function conditionalReverse(condition: boolean, data: any[]) {
  return condition ? data.filter((x) => x).reverse() : data;
}

export function stringJoin(parts: (string | undefined | null)[]) {
  return parts.filter((x) => x).join(' ');
}

export async function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function isOfType<
  Union extends { type: string },
  SpecificType extends Union['type'],
>(val: SpecificType[] | SpecificType) {
  return (obj: Union): obj is Extract<Union, { type: SpecificType }> =>
    Array.isArray(val) ? val.some((a) => a === obj.type) : obj.type === val;
}

export function isNotOfType<
  Union extends { type: string },
  SpecificType extends Union['type'],
>(val: SpecificType[] | SpecificType) {
  return (obj: Union): obj is Exclude<Union, { type: SpecificType }> =>
    Array.isArray(val) ? val.every((a) => a !== obj.type) : obj.type !== val;
}

type KeysOfUnion<T> = T extends T ? keyof T : never;

export function hasProp<K extends KeysOfUnion<U>, U>(prop: K) {
  return (
    obj: U,
  ): obj is Extract<U, { [prop in K]: NonNullable<unknown> | null }> =>
    Object.prototype.hasOwnProperty.call(obj, prop);
}

export function hasOptionalProp<K extends KeysOfUnion<U>, U>(prop: K) {
  return (
    obj: U,
  ): obj is Extract<U, { [prop in K]?: NonNullable<unknown> | null }> =>
    Object.prototype.hasOwnProperty.call(obj, prop);
}
