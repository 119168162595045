import { msg } from '@lingui/macro';
import { md, SpouseLabelData } from 'content/types';

type IsMidyear = { isMidyear: boolean };

export default {
  title: msg`Now, let’s talk about your household’s typical healthcare needs.`,
  intro: ({ isMidyear }: IsMidyear) =>
    md(msg`By telling us what a **typical year** of healthcare usage looks like for you,
we’re able to calculate your out-of-pocket costs and evaluate which combinations
fit those needs.
${'_lf'}
Later on, you’ll have a chance to note any big changes you have planned for ${
      isMidyear ? 'the rest of this plan year' : 'next year'
    }, like scheduled surgeries or having a baby, so think of this page as 
your chance to tell us what an average year looks like.`),

  calloutTitle: msg`Heads up`,
  callout: md(
    msg`We have defaulted your estimated usage to the lowest level. Make sure to review and update these levels **if last year was not a typical year.**`,
  ),
  calloutClaimsOptIn:
    md(msg`Where available, we have personalized your estimated usage based on your 
household’s claims from the past year (when not available, we default this 
to the lowest level).  
${'_lf'}
Make sure to review and update these levels **if last 
year was not a typical year.**`),

  whyDoWeNeedThis: ({ spouseLabel }: SpouseLabelData) =>
    md(
      msg`We treat the typical usage you provide on this page as claims and assign 
    them to the plans available from your employer, and ${spouseLabel}’s employer 
    if you choose to compare their plan. This allows us to determine your typical 
    out-of-pocket costs for you and your household’s healthcare needs, which is an 
    important factor in matching you to personalized benefit options.`,
    ),
};
