import { updateHousehold } from 'api/selectsmart';
import { useAuthentication } from 'auth/useAuthentication';
import PageActivityIndicator from 'components/common/PageActivityIndicator';
import { useAvailableFormStepsContext } from 'components/form/useAvailableFormStepsContext';
import { routeOrDefault, isExcludedRoute } from 'helpers/common';
import { useGlobalStore } from 'hooks/useGlobalStore';
import usePostMessage from 'hooks/usePostMessage';
import { useTrackEvent } from 'hooks/useTrackEvent';
import * as React from 'react';
import { PropsWithChildren, useEffect, useMemo } from 'react';
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { HouseholdActionTypes } from './helpers/stateUtil';
import { navigationConfig } from './navigationConfig';
import {
  NavigationSteps,
  routeNameFromPath,
  RouteNames,
  routePath,
  routes,
} from './RouteNames';
import { RouterErrorPage } from './RouterErrorPage';
import { msg } from '@lingui/macro';
import { useMaybeLingui } from 'hooks/useMaybeLingui';
import RequiredDataRedirects from 'components/RequiredDataRedirects';

const RootNavigationInnerWrapper = ({ children }: PropsWithChildren) => {
  const { dispatch } = useGlobalStore();
  const navigate = useNavigate();
  const { tracker } = useTrackEvent();
  const { userJourney, defaultFirstStep } = useAvailableFormStepsContext();
  const routeNameRef = React.useRef<string>();
  const nextStep = React.useRef<number>(0);
  const steps = useMemo(() => Object.values(NavigationSteps), []);
  const { isAuthenticated } = useAuthentication() || {};
  const location = useLocation();

  const { postMessage } = usePostMessage();

  const { _ } = useMaybeLingui();

  const onNavigationChange = React.useCallback(
    (route: string | undefined) => {
      // Nothing to do
      if (
        !route ||
        route !==
          routeOrDefault(route as RouteNames, defaultFirstStep, userJourney)
      ) {
        return;
      }

      // Local
      const updateLastLocation = async (currentRouteName: string) => {
        dispatch({
          type: HouseholdActionTypes.UPDATE_LAST_LOCATION,
          payload: currentRouteName,
        });
        return await updateHousehold({ last_location: currentRouteName });
      };

      // Update
      updateLastLocation(route);

      // Post message
      postMessage('navigate', true);
    },
    [defaultFirstStep, dispatch, postMessage, userJourney],
  );

  // On location change
  useEffect(() => {
    const previousRouteName = routeNameRef.current;
    const currentRouteName = routeNameFromPath(location.pathname);

    // Set the HAR context for automated tests
    if (window.AUTOMATED_TEST_ENV) {
      window.harContextLocation = currentRouteName;
    }

    if (
      currentRouteName &&
      !isExcludedRoute(currentRouteName) &&
      !isAuthenticated
    ) {
      navigate(routePath(RouteNames.Welcome), { replace: true });
      return;
    }

    // Refs
    const currentNav = userJourney.find((n) => n.route === currentRouteName);
    nextStep.current = currentNav
      ? steps.indexOf(currentNav.name as NavigationSteps)
      : 0;
    routeNameRef.current = currentRouteName;

    // Title
    document.title = _(currentNav?.pageTitle || currentNav?.name || '');

    // Navigation change
    if (previousRouteName !== currentRouteName) {
      onNavigationChange(currentRouteName);
    }

    // Tracking - if we track the route
    if (currentRouteName) {
      tracker?.pageView({
        path: currentRouteName,
        title: document.title,
      });
    }
  }, [location, _]);

  return <>{children}</>;
};

export const RootNavigation: React.FC = () => {
  const { globalState } = useGlobalStore();
  const { _ } = useMaybeLingui();

  const router = useMemo(
    () =>
      createBrowserRouter([
        // Need to use the full navigationConfig here to configure all possible routes.
        // Navigation to a route happens before the guidance mode/user journey is resolved
        ...navigationConfig.map(({ route, component: Component }) => ({
          path: routes[route],
          name: route,
          errorElement: <RouterErrorPage />,
          element: (
            <RootNavigationInnerWrapper>
              <React.Suspense
                fallback={
                  <PageActivityIndicator
                    description={_(msg`Loading...`)}
                    positionKey="global"
                  />
                }>
                <RequiredDataRedirects>
                  <Component />
                </RequiredDataRedirects>
              </React.Suspense>
            </RootNavigationInnerWrapper>
          ),
        })),
        {
          path: '*',
          element: (
            <Navigate
              to={routePath(
                globalState.apiState.household.last_location ||
                  RouteNames.Welcome,
              )}
              replace
            />
          ),
        },
      ]),
    [globalState.apiState.household.last_location, _],
  );

  return <RouterProvider router={router} />;
};
