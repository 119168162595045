import amplitude from 'amplitude-js';
import { Tracker, defaultPageViewPath, defaultPageViewTitle } from './tracker';
import { EventTrackingState } from 'context/eventTracking';

export class AmplitudeTracker implements Tracker {
  constructor(
    private options: {
      userId: string;
      apiKey: string;
    },
  ) {
    amplitude.getInstance().init(this.options.apiKey, this.options.userId);
  }

  private internalTrack(params: { [key: string]: unknown }) {
    const { event, ...rest } = params;
    amplitude.getInstance().logEvent(`${event}`, rest);
  }

  config(siteData: EventTrackingState['siteData']) {
    if (siteData?.employerId) {
      amplitude.getInstance().setGroup('employerId', `${siteData.employerId}`);
    }
    amplitude.getInstance().setUserProperties(siteData);
  }

  pageView(params: { path?: string; title?: string; [key: string]: unknown }) {
    this.internalTrack({
      event: 'page_view',
      path: params.path ?? defaultPageViewPath(),
      title: params.title ?? defaultPageViewTitle(),
      ...params,
    });
  }

  track({
    action,
    category,
    label,
    value,
    ...rest
  }: {
    action: string;
    category: string;
    label: string;
    value?: number;
    [key: string]: unknown;
  }) {
    this.internalTrack({
      event: 'event',
      category,
      action,
      label,
      value,
      ...rest,
    });
  }
}
