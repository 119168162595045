import DebugContext, { DebugOptions } from 'context/debug';
import React from 'react';
import { SITE } from '../../site.config';

// Expose a way to load the debug options outside of the context
export const loadDebugOptions = () => {
  if (!SITE.ENABLE_DEVELOPER_TOOLS) {
    return null;
  }
  const options = localStorage.getItem('savvi-debug');
  if (options) {
    return JSON.parse(options) as DebugOptions;
  }
  return null;
};

export const useDebug = () => {
  const { options, setOptions } = React.useContext(DebugContext);
  const update = (newOptions: Partial<DebugOptions>) =>
    setOptions((o) => ({ ...o, ...newOptions }));
  return {
    ...options,
    enabled: options.enabled && SITE.ENABLE_DEVELOPER_TOOLS,
    setEnabled: (enabled: boolean) => update({ enabled }),
    setPanelOpen: (panelOpen: boolean) => update({ panelOpen }),
    setCustomPostDate: (customPostDate: string) => update({ customPostDate }),
    setCustomPostDateEnabled: (customPostDateEnabled: boolean) =>
      update({ customPostDateEnabled }),
  };
};

export default useDebug;
