import * as React from 'react';
import { RouteNames, NavigationSteps } from 'RouteNames';
import { RootState } from 'components/store/Store';
import { Role } from 'interfaces/common';
import { filterSegmentsQuestions } from 'helpers/benefits';
import isEmpty from 'lodash/isEmpty';
import { NavigationStepConfig } from 'interfaces/navigation';
import content from 'content/values';
import { msg } from '@lingui/macro';
import { isMidYearJourney } from 'helpers/common';

const Welcome = React.lazy(() => import('screens/welcome'));
const SignIn = React.lazy(() => import('screens/SignIn'));
const SignUp = React.lazy(() => import('screens/SignUp'));
const Go = React.lazy(() => import('screens/Go'));
const WhatBringsYouHere = React.lazy(
  () => import('screens/profile/WhatBringsYouHere'),
);
const Household = React.lazy(() => import('screens/profile/ProfileHousehold'));
const NotTheToolForYou = React.lazy(
  () => import('screens/profile/NotTheToolForYou'),
);
const ProfileEmployment = React.lazy(
  () => import('screens/profile/ProfileEmployment'),
);
const Results = React.lazy(() => import('screens/results/Results'));
const BundleSummary = React.lazy(() => import('screens/results/BundleSummary'));

const Healthcare = React.lazy(() => import('screens/healthcare/Healthcare'));
const HealthcareSpecial = React.lazy(
  () => import('screens/healthcare/HealthcareSpecial'),
);
const HealthcareTobacco = React.lazy(
  () => import('screens/healthcare/HealthcareTobacco'),
);
const HealthcareYTD = React.lazy(
  () => import('screens/healthcare/HealthcareYTD'),
);
const HealthcareDTY = React.lazy(
  () => import('screens/healthcare/HealthcareDTY'),
);
const HealthSupplementalBenefits = React.lazy(
  () => import('screens/healthcare/HealthcareSupplementalBenefits'),
);
const Submit = React.lazy(() => import('screens/Submit'));
const BenefitsProfile = React.lazy(
  () => import('screens/benefits/BenefitsProfile'),
);
const BenefitsSpouse = React.lazy(
  () => import('screens/benefits/BenefitsSpouse'),
);

const EngineData = React.lazy(() => import('screens/EngineData'));
const Terms = React.lazy(() => import('screens/Terms'));

const { pageTitles } = content;

export const navigationMainSections = [
  NavigationSteps.PROFILE,
  NavigationSteps.HEALTHCARE,
  NavigationSteps.RESULTS,
];

export const navigationConfig: NavigationStepConfig[] = [
  // Credentials steps
  // shape: {
  //  name: string;
  //  route: RouteNames;
  //  component: React.ComponentType<any>;
  //}
  {
    name: RouteNames.Go,
    route: RouteNames.Go,
    component: Go,
  },
  {
    // eslint-disable-next-line lingui/no-unlocalized-strings
    name: 'Sign up',
    route: RouteNames.SignUp,
    component: SignUp,
  },
  {
    // eslint-disable-next-line lingui/no-unlocalized-strings
    name: 'Sign in',
    route: RouteNames.SignIn,
    component: SignIn,
  },
  {
    name: RouteNames.Welcome,
    route: RouteNames.Welcome,
    component: Welcome,
    pageTitle: pageTitles.welcome,
  },
  {
    name: RouteNames.EngineData,
    route: RouteNames.EngineData,
    component: EngineData,
  },
  {
    name: RouteNames.Terms,
    route: RouteNames.Terms,
    component: Terms,
  },
  {
    name: RouteNames.BundleSummary,
    route: RouteNames.BundleSummary,
    component: BundleSummary,
    pageTitle: msg`Your benefit guidance results`,
  },
  // Form steps
  // shape: {
  //  name: string;
  //  route: RouteNames;
  //  isInitialFormStep: boolean; // can be used only in one screen, used to calculate available form steps
  //  component: React.ComponentType<any>;
  //  nextStep: callback calculating next step based on state, returning RouteName or null (if it's a last step in the form)
  //}

  // Profile
  {
    name: NavigationSteps.PROFILE,
    route: RouteNames.WhatBringsYouHere,
    pageTitle: pageTitles.guidanceMode,
    isInitialFormStep: true,
    component: WhatBringsYouHere,
    useProgressBar: true,
    nextStep: (_state: RootState) => RouteNames.Household,
  },
  {
    name: NavigationSteps.PROFILE,
    route: RouteNames.Household,
    pageTitle: pageTitles.profileHousehold,
    component: Household,
    useProgressBar: false,
    nextStep: (state: RootState) => {
      const {
        apiState: {
          household: { guidance_mode, healthbenefits_mode },
        },
      } = state;
      // Local
      const client = Object.values(state.apiState.people).filter(
        (person) => person.role === Role.CLIENT,
      )[0];

      // Allow the user to load fully before processing all of these routes
      if (!client) {
        return null;
      }

      if (client.has_medicare && !client.is_covered) {
        return RouteNames.NotTheToolForYou;
      } else {
        if (!isEmpty(state.apiState?.people)) {
          const spouse = Object.values(state.apiState.people).find(
            ({ role }) => role === Role.SPOUSE,
          );
          if (
            guidance_mode === 'midyear-update' &&
            spouse &&
            spouse.has_medicare &&
            !spouse.is_covered
          ) {
            return RouteNames.NotTheToolForYou;
          }

          const questions = filterSegmentsQuestions(
            state.apiState.employer.segmentation,
            {
              isMarried: !!spouse,
            },
          );

          const collectIncomeWithSegmentation =
            state.apiState.employer.segmentation.find(
              (segment) => segment.type === 'salary-band',
            ) && state.apiState.incomes[client.id]?.source_id === null;

          if (questions.length > 0 || collectIncomeWithSegmentation) {
            return RouteNames.ProfileEmployment;
          }
        }

        const isMidyearUpdate = isMidYearJourney(state);

        // Go to benefit summary page if no benadmin integration for midyear update
        if (isMidyearUpdate) {
          return healthbenefits_mode !== 'current_benadmin'
            ? RouteNames.MidyearPlanDetails
            : RouteNames.BenefitsProfile;
        }
        return RouteNames.HealthcareUsage;
      }
    },
  },
  {
    name: NavigationSteps.PROFILE,
    route: RouteNames.NotTheToolForYou,
    component: NotTheToolForYou,
    useProgressBar: false,
    nextStep: (_state: RootState) => RouteNames.Welcome,
  },
  {
    name: NavigationSteps.PROFILE,
    route: RouteNames.ProfileEmployment,
    pageTitle: pageTitles.profileEmployment,
    component: ProfileEmployment,
    useProgressBar: true,
    nextStep: (state: RootState) => {
      const {
        apiState: {
          household: { healthbenefits_mode },
        },
      } = state;
      const isMidyearUpdate = isMidYearJourney(state);

      // Go to benefit summary page if no benadmin integration for midyear update
      if (isMidyearUpdate) {
        return healthbenefits_mode !== 'current_benadmin'
          ? RouteNames.MidyearPlanDetails
          : RouteNames.HealthcareUsage;
      }
      return RouteNames.HealthcareUsage;
    },
  },

  // Healthcare
  {
    name: NavigationSteps.HEALTHCARE,
    route: RouteNames.HealthcareUsage,
    pageTitle: pageTitles.healthcareAverageUsage,
    component: Healthcare,
    useProgressBar: true,
    omit: ({ guidanceMode }) => guidanceMode === 'midyear-update',
    nextStep: (state: RootState) => {
      // Local
      const healthcare = state.apiState.healthcare;
      const household = state.apiState.household;
      const employer = state.apiState.employer;

      // Do we expect ytd changes?
      if (Object.values(healthcare).some((p: any) => p.has_expected_ytd)) {
        return RouteNames.HealthcareYTD;
      }

      // Do we expect dty changes?
      if (Object.values(healthcare).some((p: any) => p.has_expected_dty)) {
        return RouteNames.HealthcareDTY;
      }

      // Next year changes?
      if (Object.values(healthcare).some((p: any) => p.has_special)) {
        return RouteNames.HealthcareSpecial;
      }

      // Include spouse before moving on?
      if (
        household.consider_spousal_benefits ||
        household.last_location === RouteNames.BenefitsSpouse
      ) {
        return RouteNames.BenefitsSpouse;
      }

      // Do we need to consider tobacco?
      if (employer.has_tobacco_use) {
        return RouteNames.TobaccoUsage;
      }

      // Move to next step
      return RouteNames.BenefitsProfile;
    },
  },
  {
    name: NavigationSteps.HEALTHCARE,
    route: RouteNames.HealthcareSpecial,
    pageTitle: pageTitles.healthcareSpecialUsage,
    component: HealthcareSpecial,
    useProgressBar: true,
    omit: ({ guidanceMode }) => guidanceMode === 'midyear-update',
    nextStep: (state: RootState) => {
      // Local
      const healthcare = state.apiState.healthcare;
      const household = state.apiState.household;
      const employer = state.apiState.employer;

      // Do we expect ytd changes?
      if (Object.values(healthcare).some((p: any) => p.has_expected_ytd)) {
        return RouteNames.HealthcareYTD;
      }

      // Do we expect dty changes?
      if (Object.values(healthcare).some((p: any) => p.has_expected_dty)) {
        return RouteNames.HealthcareDTY;
      }

      // Include spouse before moving on?
      if (
        household.consider_spousal_benefits ||
        household.last_location === RouteNames.BenefitsSpouse
      ) {
        return RouteNames.BenefitsSpouse;
      }

      // Do we need to consider tobacco?
      if (employer.has_tobacco_use) {
        return RouteNames.TobaccoUsage;
      }

      // Move to next step
      return RouteNames.BenefitsProfile;
    },
  },
  {
    name: NavigationSteps.HEALTHCARE,
    route: RouteNames.HealthcareYTD,
    pageTitle: pageTitles.healthcareSpecialUsage,
    component: HealthcareYTD,
    useProgressBar: true,
    omit: ({ guidanceMode }) => guidanceMode === 'midyear-update',
    nextStep: (state: RootState) => {
      // Local
      const employer = state.apiState.employer;
      const household = state.apiState.household;

      // Do we expect dty changes?
      if (
        Object.values(state.apiState.healthcare).some(
          (p: any) => p.has_expected_dty,
        )
      ) {
        return RouteNames.HealthcareDTY;
      }

      // Include spouse before moving on?
      if (
        household.consider_spousal_benefits ||
        household.last_location === RouteNames.BenefitsSpouse
      ) {
        return RouteNames.BenefitsSpouse;
      }

      // Do we need to consider tobacco?
      if (employer.has_tobacco_use) {
        return RouteNames.TobaccoUsage;
      }

      // Move to next step
      return RouteNames.BenefitsProfile;
    },
  },
  {
    name: NavigationSteps.HEALTHCARE,
    route: RouteNames.HealthcareDTY,
    pageTitle: pageTitles.healthcareSpecialUsage,
    component: HealthcareDTY,
    useProgressBar: true,
    omit: ({ guidanceMode }) => guidanceMode === 'midyear-update',
    nextStep: (state: RootState) => {
      // Local
      const employer = state.apiState.employer;
      const household = state.apiState.household;

      // Include spouse before moving on?
      if (
        household.consider_spousal_benefits ||
        household.last_location === RouteNames.BenefitsSpouse
      ) {
        return RouteNames.BenefitsSpouse;
      }

      // Do we need to consider tobacco?
      if (employer.has_tobacco_use) {
        return RouteNames.TobaccoUsage;
      }

      // Move to next step
      return RouteNames.BenefitsProfile;
    },
  },
  {
    name: NavigationSteps.HEALTHCARE,
    route: RouteNames.BenefitsSpouse,
    pageTitle: pageTitles.partnerBenefits,
    component: BenefitsSpouse,
    useProgressBar: true,
    omit: ({ guidanceMode }) => guidanceMode === 'midyear-update',
    nextStep: (state: RootState) => {
      // Local
      const employer = state.apiState.employer;

      // Do we need to consider tobacco?
      if (employer.has_tobacco_use) {
        return RouteNames.TobaccoUsage;
      }

      // Move to next step
      return RouteNames.BenefitsProfile;
    },
  },
  {
    name: NavigationSteps.HEALTHCARE,
    route: RouteNames.TobaccoUsage,
    pageTitle: pageTitles.tobaccoUsage,
    component: HealthcareTobacco,
    useProgressBar: true,
    nextStep: (_state: RootState) => RouteNames.BenefitsProfile,
  },
  {
    name: NavigationSteps.HEALTHCARE,
    route: RouteNames.BenefitsProfile,
    pageTitle: pageTitles.benefitsProfile,
    component: BenefitsProfile,
    useProgressBar: true,
    nextStep: () => RouteNames.HealthcareSupplementalBenefits,
  },

  {
    name: NavigationSteps.HEALTHCARE,
    route: RouteNames.HealthcareSupplementalBenefits,
    pageTitle: pageTitles.supplementalBenefits,
    component: HealthSupplementalBenefits,
    useProgressBar: true,
    nextStep: (_state: RootState) => RouteNames.Submit,
  },

  {
    name: NavigationSteps.HEALTHCARE,
    route: RouteNames.Submit,
    pageTitle: pageTitles.submit,
    useProgressBar: false,
    component: Submit,
    nextStep: (_state: RootState) => RouteNames.Results,
  },
  {
    name: NavigationSteps.RESULTS,
    route: RouteNames.Results,
    pageTitle: pageTitles.results,
    useProgressBar: true,
    component: Results,
    nextStep: () => null,
  },
];

// Set a default welcome screen for the app to default to
export const defaultFirstStep = RouteNames.Household;
