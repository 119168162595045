/* eslint-disable @typescript-eslint/no-unused-vars */
import { FormikErrors } from 'formik';
import { Dictionary, Nullable, Role } from './common';
import { SummaryResponseBundle } from './summary';
import { msg } from '@lingui/macro';

export enum MedicalPlanKind {
  HMO = 'HMO',
  PPO = 'PPO',
  EPO = 'EPO',
  POS = 'POS',
}

export const medicalPlanLabel = {
  HMO: msg`HMO (Health Maintenance Organization plan)`,
  PPO: msg`PPO (Preferred Provider Organization plan)`,
  EPO: msg`EPO (Exclusive Provider Organization plan)`,
  POS: msg`POS (Point-of-Service plan)`,
};

export enum PlanFrequency {
  MONTHLY = 'monthly',
  SEMIMONTHLY = 'semimonthly',
  WEEKLY = 'weekly',
  BIWEEKLY = 'biweekly',
  ANNUALLY = 'annually',
}

export enum BenefitItemType {
  MEDICAL_PLAN = 'medical_plan',
  DENTAL_PLAN = 'dental_plan',
  VISION_PLAN = 'vision_plan',
  HSA = 'hsa',
  LPFSA = 'lpfsa',
  FSA = 'fsa',
  HRA = 'hra',
  ACCIDENT_INDEMNITY = 'accident_indemnity',
  ILLNESS_INDEMNITY = 'illness_indemnity',
  ACCIDENT = 'accident_indemnity',
  ILLNESS = 'illness_indemnity',
  HOSPITAL_INDEMNITY = 'hospital_indemnity',
  LIFE_INSURANCE = 'life_insurance',
  BASIC_LIFE_INSURANCE = 'basic_life_insurance',
  STD_INSURANCE = 'std_insurance',
  BASIC_STD_INSURANCE = 'basic_std_insurance',
  LTD_INSURANCE = 'ltd_insurance',
  BASIC_LTD_INSURANCE = 'basic_ltd_insurance',
  ADD_INSURANCE = 'add_insurance',
  BASIC_ADD_INSURANCE = 'basic_add_insurance',
  HEALTH_SAVINGS = 'hsa',
  HSA_OR_FSA = 'hsa_or_fsa',
  EMPLOYER_CONTRIBUTION = 'employer_contribution',
  REIMBURSEMENT_HRA = 'reimbursement_hra',
}

export const NEXT_DOLLAR_INSURANCES = [
  BenefitItemType.LIFE_INSURANCE,
  BenefitItemType.BASIC_LIFE_INSURANCE,
  BenefitItemType.STD_INSURANCE,
  BenefitItemType.BASIC_STD_INSURANCE,
  BenefitItemType.LTD_INSURANCE,
  BenefitItemType.BASIC_LTD_INSURANCE,
  BenefitItemType.ADD_INSURANCE,
  BenefitItemType.BASIC_ADD_INSURANCE,
];

export enum BenefitsGroupType {
  MEDICAL_PLANS = 'Health Plans',
  DENTAL_PLANS = 'Dental Plans',
  VISION_PLANS = 'Vision Plans',
  HEALTH_ACCOUNTS = 'Health Accounts',
  SUPPLEMENTAL_HEALTH = 'Supplemental Benefits',
}

export type Levels = {
  level: number | null;
  level_spouse?: number | null;
  level_child?: number | null;
};

export const nonEditableTypes = [
  BenefitItemType.FSA,
  BenefitItemType.LPFSA,
  BenefitItemType.HSA,
  BenefitItemType.HRA,
  BenefitItemType.ACCIDENT_INDEMNITY,
  BenefitItemType.HOSPITAL_INDEMNITY,
  BenefitItemType.ILLNESS_INDEMNITY,
];

export type BenefitMinMaxLevels = Dictionary<{ min: number; max: number }>;

export type BenefitItem = {
  plan_id: string;
  life_plan_id: string;
  sliders: any;
  type: BenefitItemType;
  id: string;
  person_id: string;
  name: string;
  notes: string[];
  employer_id: Nullable<string>;
  segment: null;
  spouse_exists: boolean;
  child_exists: boolean;
  is_spouse_eligible: boolean;
  is_spouse_level_conditional: boolean;
  spouse_min_max_levels: BenefitMinMaxLevels;
  are_children_eligible: boolean;
  is_child_level_conditional: boolean;
  child_min_max_levels: BenefitMinMaxLevels;
  requires_one_of: Nullable<string[]>;
  incompatible_with: Nullable<string[]>;
  kind: MedicalPlanKind | null;
  premium: number;
  premium_frequency: PlanFrequency;
  annual_limits: object;
  premiums: {
    individual: number;
    plus_spouse: number;
    plus_children: number;
    family: number;
  };
  segments: null;
  in_network: {
    copay: number;
    coinsurance: number;
    is_deductible_waived: boolean;
    has_preventive_care: boolean;
    specific_deductible: null;
    specific_out_of_pocket_limits: null;
    specific_annual_limits: {
      orthodontics: {
        individual: number;
      };
    };
    deductibles: {
      individual: number;
      family: number;
    };
    annual_limits: {
      individual: number;
      family: number;
    };
    out_of_pocket_limits: {
      single: number | null;
      individual: number;
      family: number;
    };
  };
  excluded_services: [];
  services: {
    orthodontics: {
      in_network: {
        coinsurance: number;
        specific_annual_limit: number | string;
      };
    };
    drugs_generic: {
      in_network: {
        coinsurance: number;
        copay: number;
        is_deductible_waived: boolean;
      };
    };
  };
  lifetime_limits: null;
  current_balance: number;
  employer_contribution: number;
  employer_contributions: object;
  contribution_frequency: PlanFrequency;
  is_investable: boolean;
  is_hdhp: boolean;
  employer_match_limit: number;
  wellness_benefit: any;
  allows_in_service_rollovers: boolean;
  allows_retirement_rollover: boolean;
  uncovered_amount: number;
  uncovered_amounts: object;
  levels?: number[];
  levels_child?: number[];
  levels_spouse?: number[];
  levels_premiums?: any;
  is_household_rate?: boolean;
  base_id?: string;
  elected_levels?: any;
  person_role?: Role;
  add_plan_id?: string;
  current_selections?: {
    subscriber: number;
    spouse: number;
    child: number;
  };
  employer_match?: [];
};

export type BenefitValidationItemCommon = {
  id: string;
  name: string;
};

export type FamilyAndIndividual = {
  family: number;
  individual: number;
};

export type ProtectionItem = {
  max_amount: number;
  is_section125: any;
  coverage_frequency: string;
  name: string;
  notes: string[];
  sliders: any;
  plan_id: string;
  id: string;
  type: BenefitItemType;
  base_id: string;
  description: string | null;
};

export type MedicalPlanValidationItem = BenefitValidationItemCommon & {
  // UI - Form fields
  name: string;
  type: BenefitItemType.MEDICAL_PLAN;
  kind: MedicalPlanKind;
  premium: number | null;
  premium_frequency: PlanFrequency;

  premiums_individual: number | null;
  premiums_plus_spouse: number | null;
  premiums_plus_children: number | null;
  premiums_family: number | null;

  copay: number | null;
  coinsurance: number | null;
  deductibles_individual: number | null;
  deductibles_family: number | null;
  oop_individual: number | null;
  oop_family: number | null;

  is_hdhp?: boolean;

  rx_copay: number | null;
  rx_coinsurance: number | null;

  services: any;
};

export type VisionPlanValidationItem = BenefitValidationItemCommon & {
  premiums_individual: number;
  premiums_plus_spouse: number;
  premiums_plus_children: number;
  premiums_family: number;
  premium_frequency: PlanFrequency;
  in_network_allowance: number;
  in_network_discount: number;
  services: any;

  service_contact_lens_fitting_copay: number | null;
  service_contact_lens_fitting_coinsurance: number | null;
  service_contact_lens_fitting_allowance: number | null;
  service_contact_lens_fitting_discount: number | null;
  service_contact_lenses_copay: number | null;
  service_contact_lenses_coinsurance: number | null;
  service_contact_lenses_allowance: number | null;
  service_contact_lenses_discount: number | null;
  service_frames_copay: number | null;
  service_frames_coinsurance: number | null;
  service_frames_allowance: number | null;
  service_frames_discount: number | null;
  service_glasses_copay: number | null;
  service_glasses_coinsurance: number | null;
  service_glasses_allowance: number | null;
  service_glasses_discount: number | null;
  service_routine_eye_care_copay: number | null;
  service_routine_eye_care_coinsurance: number | null;
  service_routine_eye_care_allowance: number | null;
  service_routine_eye_care_discount: number | null;
  service_vision_procedure_copay: number | null;
  service_vision_procedure_coinsurance: number | null;
  service_vision_procedure_allowance: number | null;
  service_vision_procedure_discount: number | null;

  type: BenefitItemType.VISION_PLAN;
  kind: MedicalPlanKind;
  copay: number;
  coverage: number;
  coinsurance: number;
  has_preventive_care: boolean;
  individual_deductible: number;
  individual_annual_limits: number;
  annual_limits: FamilyAndIndividual;
  are_children_covered: boolean;
  deductibles: FamilyAndIndividual;
  is_spouse_covered: boolean;
  is_variable_by_service: boolean;
  out_of_pocket_limits: FamilyAndIndividual;
};

export type DentalPlanValidationItem = BenefitValidationItemCommon & {
  premiums_individual: number;
  premiums_plus_spouse: number;
  premiums_plus_children: number;
  premiums_family: number;
  premium_frequency: PlanFrequency;

  copay: number;
  coinsurance: number;
  deductibles_individual: number;
  annual_limit_individual: number;

  type: BenefitItemType.DENTAL_PLAN;
  kind: MedicalPlanKind;
  has_preventive_care: boolean;

  individual_deductible: number;

  individual_annual_limits: number;
  annual_limits: FamilyAndIndividual;
  are_children_covered: boolean;
  is_spouse_covered: boolean;
  is_variable_by_service: boolean;

  ortho_coinsurance: number;
  ortho_limit: number;
};

export type HSAValidationItem = BenefitValidationItemCommon & {
  type: BenefitItemType.HSA;
  employer_contributions_individual: number;
  employer_contributions_family: number;
  contribution_frequency: PlanFrequency;
  employer_match_limit: number;
  current_balance: number;
};

export type FSAValidationItem = BenefitValidationItemCommon & {
  type: BenefitItemType.FSA;
  employer_contribution: number;
  contribution_frequency: PlanFrequency;
  employer_match_limit: number;
  employer_contributions_individual: number;
  employer_contributions_family: number;
  employer_contributions: {
    individual: number;
    family: number;
  };
};

export type LPFSAValidationItem = Omit<FSAValidationItem, 'type'> & {
  type: BenefitItemType.LPFSA;
};

export type HRAValidationItem = BenefitValidationItemCommon & {
  type: BenefitItemType.HRA;
  employer_contribution: number;
  contribution_frequency: PlanFrequency;
  uncovered_amount: number;
  current_balance: number;
  employer_contributions_individual: number;
  employer_contributions_family: number;
  employer_contributions: {
    individual: number;
    family: number;
  };
};

export type SupplementalHealthValidationItem = BenefitValidationItemCommon & {
  type:
    | BenefitItemType.ACCIDENT_INDEMNITY
    | BenefitItemType.ILLNESS_INDEMNITY
    | BenefitItemType.HOSPITAL_INDEMNITY;
  premium: number;
  premiums_individual: any;
  premium_frequency: PlanFrequency;
};

export type HealthAccountValidationItem =
  | HSAValidationItem
  | FSAValidationItem
  | LPFSAValidationItem
  | HRAValidationItem;

export type BenefitValidationItem =
  | MedicalPlanValidationItem
  | VisionPlanValidationItem
  | DentalPlanValidationItem
  | HealthAccountValidationItem
  | SupplementalHealthValidationItem;

export type BenefitValidationValues = BenefitValidationItem[];

export type BenefitMedicalItem = {
  type: BenefitItemType.MEDICAL_PLAN;
  employer_id: Nullable<string>;
  id: string;
  person_id: string;
  name: string;
  is_spouse_eligible: boolean;
  kind: MedicalPlanKind | null;
  is_hdhp: boolean;
  is_considered: boolean;
  plan_id: string;
  premium_frequency: PlanFrequency;
  premiums: {
    individual: number;
    plus_spouse: number;
    plus_children: number;
    family: number;
  };
  specific_annual_limits: any;
  services: {
    drugs_generic: {
      in_network: {
        coinsurance: number;
        copay: number;
        is_deductible_waived: boolean;
      };
    };
  };
};

export type BenefitDentalItem = {
  type: BenefitItemType.DENTAL_PLAN;
  id: string;
  person_id: string;
  name: string;
  plan_id: string;
  kind: MedicalPlanKind | null;
  employer_id: Nullable<string>;
  premium: number;
  is_considered: boolean;
  premium_frequency: PlanFrequency;
  has_preventive_care: boolean;
  specific_annual_limits: any;
  premiums: {
    individual: number;
    plus_spouse: number;
    plus_children: number;
    family: number;
  };
  in_network: {
    copay: number;
    coinsurance: number;
    has_preventive_care: boolean;
    deductibles: {
      individual: number;
    };
    annual_limits: {
      individual: number;
    };
    specific_annual_limits: {
      orthodontics: {
        individual: number;
      };
    };
  };
};

export type BenefitVisionItem = {
  type: BenefitItemType.VISION_PLAN;
  id: string;
  person_id: string;
  name: string;
  plan_id: string;
  kind: MedicalPlanKind | null;
  employer_id: Nullable<string>;
  is_considered: boolean;
  premium_frequency: PlanFrequency;
  specific_annual_limits: any;
  premiums: {
    individual: number;
    plus_spouse: number;
    plus_children: number;
    family: number;
  };
  in_network: {
    copay: number;
    coinsurance: number;
    has_preventive_care: boolean;
    deductibles: {
      individual: number;
    };
    annual_limits: {
      individual: number;
    };
  };
};

export type BenefitHSAItem = {
  type: BenefitItemType.HSA;
  employer_id: Nullable<string>;
  id: string;
  person_id: string;
  name: string;
  is_considered: boolean;
  plan_id: string;
  current_balance: number;
  contribution_frequency: PlanFrequency;
  employer_match_limit: number;
  employer_contributions_individual: number;
  employer_contributions_family: number;
  employer_contributions: {
    individual: number;
    family: number;
  };
  specific_annual_limits: any;
};

export interface BenefitFSAItem {
  type: BenefitItemType.FSA;
  employer_id: Nullable<string>;
  id: string;
  plan_id: string;
  person_id: string;
  is_considered: boolean;
  name: string;
  employer_contributions_individual: number;
  employer_contributions_family: number;
  employer_contributions: {
    individual: number;
    family: number;
  };
  employer_match_limit: number;
  contribution_frequency: PlanFrequency;
  specific_annual_limits: any;
}

export interface BenefitLPFSAItem extends Omit<BenefitFSAItem, 'type'> {
  type: BenefitItemType.LPFSA;
  is_considered: boolean;
}

export interface BenefitHRAItem {
  type: BenefitItemType.HRA;
  id: string;
  person_id: string;
  employer_id: Nullable<string>;
  plan_id: string;
  is_considered: boolean;
  name: string;
  employer_contributions_individual: number;
  employer_contributions_family: number;
  employer_contributions: {
    individual: number;
    family: number;
  };
  contribution_frequency: PlanFrequency;
  uncovered_amount: number;
  current_balance: number;
}

export type BenefitAccidentItem = {
  id: string;
  name: string;
  person_id: string;
  is_considered: boolean;
  plan_id: string;
  employer_id: Nullable<string>;
  type: BenefitItemType.ACCIDENT_INDEMNITY;
  premium: number;
  premium_frequency: PlanFrequency;
};

export interface BenefitHospitalItem extends Omit<BenefitAccidentItem, 'type'> {
  type: BenefitItemType.HOSPITAL_INDEMNITY;
  is_considered: boolean;
}

export interface BenefitIllnessItem extends Omit<BenefitAccidentItem, 'type'> {
  type: BenefitItemType.ILLNESS_INDEMNITY;
  is_considered: boolean;
}

export interface PlanCardData<BenefitItem extends BenefitValidationItem> {
  item?: BenefitItem;
  bundle?: SummaryResponseBundle;
}

export interface PlanCardProps<BenefitItem extends BenefitValidationItem>
  extends PlanCardData<BenefitItem> {
  errors?: FormikErrors<BenefitItem>;
  forceExpanded?: boolean;
  id: string;
  setFieldValue(field: string, value: any): void;
  benefitIndex: number;
  onRemove?(id: string): void;
  onSave(): void;
  saveEdits(
    type: BenefitItemType,
    id: string,
    planData: any,
    benefitData: any,
  ): Promise<void>;
  onSubmit(): void;
  onCancel(): void;
}

export interface BenefitSegmentChoiceOption {
  label: string;
  value: string;
}

export enum BenefitSegmentQuestionType {
  MULTIPLE_CHOICE = 'multiple-choice',
  STATE_OF_RESIDENCE = 'state-of-residence',
  SALARY_BAND = 'salary-band',
}

export type Account = {
  admin_url?: string | null | undefined;
  type: string;
  id: string;
  household_id: string;
  person_id: string;
  employer_id: string;
  plan_id: string;
  name: string;
  source: string;
  source_id: string;
  created_at: string;
  updated_at: string;
  balance: number;
  contribution: {
    amount_ytd: number;
    frequency: string;
    rate: number;
    type: string;
  };
  employer_contribution: {
    amount_ytd: number;
    frequency: string;
    rate: number;
    type: string;
  };
  status: 'active' | 'inactive';
};

export type BenefitUses = {
  plan_id: string;
  benefit_id: string;
  // coverage: null;
  // coverage_end_date: null;
  // coverage_start_date: null;
  covered_people_ids: string[];
  coverage_levels: {
    subscriber: number;
    child: number;
    spouse: number;
  };
  // deduction_end_date: null;
  // deduction_start_date: null;
  // employer_premium: null;
  household_id: string;
  id: string;
  is_change_allowed: boolean;
  is_considered: boolean;
  is_eligible: boolean;
  is_enrolled: boolean;
  is_selected: boolean;
  person_id: string;
  premium: number;
  premium_frequency: string;
  // source: 'user';
  // source_id: null;
  type: string;
  current_selections: {
    subscriber: string;
    spouse: string;
    child: string;
  };
};
