/* eslint-disable lingui/no-unlocalized-strings */
// Mui
import { type ThemeOptions } from '@mui/material';

// Local Util
const isLightMode = (theme: any) => {
  return theme.palette.mode === 'light';
};

// Local
const pageBgGradient = '#551B57';

// Mui default - Light Colors
export const paletteLight: ThemeOptions['palette'] = {
  mode: 'light',
  primary: {
    main: '#d75426',
  },
  secondary: {
    main: '#145a7b',
  },
  background: {
    default: '#F2F2F2',
    paper: '#ffffff',
  },
};

// Mui default - Dark Colors
export const paletteDark: ThemeOptions['palette'] = {
  mode: 'dark',
  primary: {
    main: '#d75426',
  },
  secondary: {
    main: '#145a7b',
  },
  background: {
    default: '#131313',
    paper: '#222222',
  },
};

// Mui - Granular Overrides
export const components: ThemeOptions['components'] = {
  MuiCssBaseline: {
    styleOverrides: (theme) => ({
      body: {
        ' .appBody': {
          backgroundImage:
            isLightMode(theme) &&
            `linear-gradient(359.28deg, ${theme.palette.background.default} 4.4%, ${pageBgGradient} 91.56%)`,
        },
        ' .actionScreen-cross-off-list': {
          ' >  .MuiTypography-root': {
            backgroundColor: pageBgGradient,
            color: '#fff',
          },
        },
        ' .chartBackground1': {
          backgroundColor: '#D75426',
          fill: '#D75426',
        },
        ' .chartBackground2': {
          backgroundColor: '#F19349',
          fill: '#F19349',
        },
      },
    }),
  },
};

// Mui - typography
export const typography: ThemeOptions['typography'] = {
  fontFamily: ['proxima-nova', 'sans-serif'].join(),
};
