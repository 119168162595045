import { useGlobalStore } from 'hooks/useGlobalStore';
import React, { createContext, useCallback, useEffect, useRef } from 'react';
import { DEPLOY_ENV, SETTINGS, SITE } from '../../../site.config';
import {
  Tracker,
  DevTracker,
  GTMTracker,
  MulticastTracker,
  GA4Tracker,
  AmplitudeTracker,
  HeapTracker,
} from 'services/tracker';

// Types
export type EventTrackingState = {
  status?: 'disabled' | 'ready' | 'loading';
  siteData?: {
    householdId?: string;
    userId?: string;
    guidanceMode?: string;
    employerId?: string;
    employerName?: string;
    site?: string;
    appName?: string;
    appVersion?: string;
    externalId?: string;
    clientId?: string;
    planId?: string;
    subscriberId?: string;
  };
  tracker?: Tracker;
};

// Local
const disableTracking =
  // @ts-ignore
  window.AUTOMATED_TEST_ENV || window.location.hostname === 'localhost'
    ? true
    : false;

// New context
export const EventTrackingContext = createContext<EventTrackingState | null>(
  null,
);

// Provider
export const EventTrackingProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  // Store
  const {
    globalState: {
      apiState: { household },
      auth,
    },
  } = useGlobalStore();

  // Callback to load site data
  const loadSiteData = useCallback(() => {
    // Default
    const data: EventTrackingState['siteData'] = {
      householdId: household.household_id ?? auth.user?.group_id,
      userId: auth.user?.id,
      guidanceMode: household?.guidance_mode || undefined,
      employerId: auth.metadata?.employer_id,
      employerName: auth.metadata?.employer_name,
      site: SITE.ID,
      appName: SITE.NAME,
      appVersion: DEPLOY_ENV,
    };

    // Custom
    if (SITE.ID === 'myvoyage') {
      data.externalId =
        household.external_ids?.voya_party_id ??
        auth.metadata?.voya_party_id ??
        undefined;
      data.clientId =
        household.external_ids?.voya_client_id ??
        auth.metadata?.voya_client_id ??
        undefined;
      data.planId =
        household.external_ids?.voya_plan_id ??
        auth.metadata?.voya_plan_id ??
        undefined;
    } else if (SITE.ID === 'benefitfocus') {
      data.externalId =
        household.external_ids?.bf_user_id ?? auth.user?.username ?? undefined;
      data.subscriberId = household.external_ids?.bf_subscriber_id || undefined;
    }

    // Set
    return data;
  }, [
    auth.metadata?.employer_id,
    auth.metadata?.employer_name,
    auth.metadata?.voya_client_id,
    auth.metadata?.voya_party_id,
    auth.metadata?.voya_plan_id,
    auth.user?.group_id,
    auth.user?.id,
    auth.user?.username,
    household.external_ids?.bf_subscriber_id,
    household.external_ids?.bf_user_id,
    household.external_ids?.voya_client_id,
    household.external_ids?.voya_party_id,
    household.external_ids?.voya_plan_id,
    household?.guidance_mode,
    household.household_id,
  ]);

  // Ref
  const state = useRef<EventTrackingState>({
    status: disableTracking ? 'disabled' : 'loading',
  });

  // Site data
  if (!state.current.siteData) {
    state.current.siteData = loadSiteData();
  }

  // Tracker not set up yet, but we have a user
  if (state.current?.siteData?.userId && !state.current.tracker) {
    // Initialize trackers
    const trackers: Tracker[] = [];

    // If not disabled, add trackers
    if (!disableTracking) {
      if (window.savviDebug) {
        trackers.push(new DevTracker());
      }

      if (window.dataLayer) {
        trackers.push(new GTMTracker());
      }

      const measurementId = SETTINGS.ANALYTICS?.GA4_MEASUREMENT_ID;
      if (measurementId) {
        trackers.push(
          new GA4Tracker({
            measurementId,
          }),
        );
      }

      const amplitudeApiKey = SETTINGS.ANALYTICS?.AMPLITUDE_API_KEY;
      if (amplitudeApiKey) {
        trackers.push(
          new AmplitudeTracker({
            userId: state.current.siteData.userId,
            apiKey: amplitudeApiKey,
          }),
        );
      }

      // @ts-ignore
      const heapAppId = SETTINGS.ANALYTICS?.HEAP_APP_ID;
      if (heapAppId) {
        trackers.push(
          new HeapTracker({
            appId: heapAppId,
          }),
        );
      }
    }

    // Set
    state.current.tracker = new MulticastTracker(
      trackers,
      state.current.siteData,
    );

    // If not disabled, set status to ready
    if (!disableTracking) {
      state.current.status = 'ready';
    }
  }

  // Monitor sources and update site data
  useEffect(() => {
    state.current.siteData = loadSiteData();
  }, [
    household.household_id,
    household.guidance_mode,
    household.external_ids,
    auth.user,
    auth.metadata.employer_id,
    auth.metadata.employer_name,
    auth.metadata.voya_party_id,
    auth.metadata.voya_client_id,
    auth.metadata.voya_plan_id,
    state.current.status,
    loadSiteData,
  ]);

  return (
    <EventTrackingContext.Provider value={state.current}>
      {children}
    </EventTrackingContext.Provider>
  );
};
