import { FormikHelpers, FormikState } from 'formik';
import { State as AuthState } from '../reducers/auth';
import { State as RetirementState } from '../reducers/retirement';
import { State as SettingsState } from '../reducers/settings';
import {
  Account,
  BenefitAccidentItem,
  BenefitDentalItem,
  BenefitFSAItem,
  BenefitHospitalItem,
  BenefitHRAItem,
  BenefitHSAItem,
  BenefitIllnessItem,
  BenefitLPFSAItem,
  BenefitMedicalItem,
  BenefitUses,
  BenefitVisionItem,
} from './benefits';
import { Bundles } from './bundles';
import { BenefitSegmentQuestion } from './employer';
import { EmergencyFundType, RetirementGoal } from './goals';
import { BudgetingType } from './budgeting';
import statesList from 'data/states.json';
import {
  Household,
  Person,
  PersonHealthcare,
  PersonIncome,
  TobaccoUse,
} from 'interfaces/household';
import { MessageDescriptor } from '@lingui/core';
import { msg } from '@lingui/macro';

export type OptionValue = string | number | boolean;

export interface OptionItem {
  label: string;
  value: OptionValue;
}

export enum ApiStatus {
  REQUESTED = 'requested',
  CANCELLED = 'cancelled',
  COMPLETED = 'completed',
  ERROR = 'error',
  READY = 'ready',
}

export enum DataSource {
  DEFAULT = 'default',
  VOYA = 'voya-api',
  MANUAL = 'manual',
  WORKDAY = 'workday',
  SPONSOR = 'sponsor',
  ADP = 'adp',
  USER = 'user',
  EMPLOYER = 'employer',
}

export interface OptionItemType<T extends OptionValue> {
  label: string | React.ReactElement | MessageDescriptor;
  value: T;
}

export type Nullable<T> = T | null;

export enum CommonActionTypes {
  SETUP_STORE = 'SETUP_STORE',
  RESET_STORE = 'RESET_STORE',
  SETUP_EMPLOYER_DATA = 'SETUP_EMPLOYER_DATA',
}

export interface Dictionary<T> {
  [key: string]: T;
}

export type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

export const DECLINED = '_';

export enum Role {
  CLIENT = 'client',
  SPOUSE = 'spouse',
  DEPENDENT = 'dependent',
}

export enum Months {
  JANUARY = 'January',
  FEBRUARY = 'February',
  MARCH = 'March',
  APRIL = 'April',
  MAY = 'May',
  JUNE = 'June',
  JULY = 'July',
  AUGUST = 'August',
  SEPTEMBER = 'September',
  OCTOBER = 'October',
  NOVEMBER = 'November',
  DECEMBER = 'December',
}

export const i18nMonthLabel = (month: Months) => {
  switch (month) {
    case Months.JANUARY:
      return msg`January`;
    case Months.FEBRUARY:
      return msg`February`;
    case Months.MARCH:
      return msg`March`;
    case Months.APRIL:
      return msg`April`;
    case Months.MAY:
      return msg`May`;
    case Months.JUNE:
      return msg`June`;
    case Months.JULY:
      return msg`July`;
    case Months.AUGUST:
      return msg`August`;
    case Months.SEPTEMBER:
      return msg`September`;
    case Months.OCTOBER:
      return msg`October`;
    case Months.NOVEMBER:
      return msg`November`;
    case Months.DECEMBER:
      return msg`December`;
  }
};

export const i18nShortMonthLabel = (month: Months) => {
  switch (month) {
    case Months.JANUARY:
      return msg`Jan`;
    case Months.FEBRUARY:
      return msg`Feb`;
    case Months.MARCH:
      return msg`Mar`;
    case Months.APRIL:
      return msg`Apr`;
    case Months.MAY:
      return msg`May`;
    case Months.JUNE:
      return msg`Jun`;
    case Months.JULY:
      return msg`Jul`;
    case Months.AUGUST:
      return msg`Aug`;
    case Months.SEPTEMBER:
      return msg`Sep`;
    case Months.OCTOBER:
      return msg`Oct`;
    case Months.NOVEMBER:
      return msg`Nov`;
    case Months.DECEMBER:
      return msg`Dec`;
  }
};

export type Month = {
  month: Months;
  label: MessageDescriptor;
  shortLabel: MessageDescriptor;
  value: string;
};

export const january: Month = {
  month: Months.JANUARY,
  label: i18nMonthLabel(Months.JANUARY),
  shortLabel: i18nShortMonthLabel(Months.JANUARY),
  value: '01',
};
export const february: Month = {
  month: Months.FEBRUARY,
  label: i18nMonthLabel(Months.FEBRUARY),
  shortLabel: i18nShortMonthLabel(Months.FEBRUARY),
  value: '02',
};
export const march: Month = {
  month: Months.MARCH,
  label: i18nMonthLabel(Months.MARCH),
  shortLabel: i18nShortMonthLabel(Months.MARCH),
  value: '03',
};
export const april: Month = {
  month: Months.APRIL,
  label: i18nMonthLabel(Months.APRIL),
  shortLabel: i18nShortMonthLabel(Months.APRIL),
  value: '04',
};
export const may: Month = {
  month: Months.MAY,
  label: i18nMonthLabel(Months.MAY),
  shortLabel: i18nShortMonthLabel(Months.MAY),
  value: '05',
};
export const june: Month = {
  month: Months.JUNE,
  label: i18nMonthLabel(Months.JUNE),
  shortLabel: i18nShortMonthLabel(Months.JUNE),
  value: '06',
};
export const july: Month = {
  month: Months.JULY,
  label: i18nMonthLabel(Months.JULY),
  shortLabel: i18nShortMonthLabel(Months.JULY),
  value: '07',
};
export const august: Month = {
  month: Months.AUGUST,
  label: i18nMonthLabel(Months.AUGUST),
  shortLabel: i18nShortMonthLabel(Months.AUGUST),
  value: '08',
};
export const september: Month = {
  month: Months.SEPTEMBER,
  label: i18nMonthLabel(Months.SEPTEMBER),
  shortLabel: i18nShortMonthLabel(Months.SEPTEMBER),
  value: '09',
};
export const october: Month = {
  month: Months.OCTOBER,
  label: i18nMonthLabel(Months.OCTOBER),
  shortLabel: i18nShortMonthLabel(Months.OCTOBER),
  value: '10',
};
export const november: Month = {
  month: Months.NOVEMBER,
  label: i18nMonthLabel(Months.NOVEMBER),
  shortLabel: i18nShortMonthLabel(Months.NOVEMBER),
  value: '11',
};
export const december: Month = {
  month: Months.DECEMBER,
  label: i18nMonthLabel(Months.DECEMBER),
  shortLabel: i18nShortMonthLabel(Months.DECEMBER),
  value: '12',
};

export const parseDateInLocalTimezone = (date: string): Date => {
  return new Date(`${date}T00:00:00`);
};

export const monthFromDate = (date: Date): Month | undefined => {
  return monthsList.find((m) => +m.value === +date.getMonth() + 1) || undefined;
};

export const monthsList = [
  january,
  february,
  march,
  april,
  may,
  june,
  july,
  august,
  september,
  october,
  november,
  december,
];

export enum PayFrequency {
  WEEKLY = 'weekly',
  WEEKLY48 = 'weekly48',
  BIWEEKLY = 'biweekly',
  // BIWEEKLY24 = 'biweekly24',
  SEMIMONTHLY = 'semimonthly',
  MONTHLY = 'monthly',
  ANNUALLY = 'annually',
  DTY = 'dty',
}

type FrequencyValues = { [key in PayFrequency]: number };
type FrequencyLabels = { [key in PayFrequency]: MessageDescriptor };
type FrequencyDescriptions = { [key in PayFrequency]: MessageDescriptor };

export const defaultFrequencyValues: FrequencyValues = {
  [PayFrequency.WEEKLY]: 52,
  [PayFrequency.WEEKLY48]: 48,
  [PayFrequency.BIWEEKLY]: 26,
  // [PayFrequency.BIWEEKLY24]: 24,
  [PayFrequency.SEMIMONTHLY]: 24,
  [PayFrequency.MONTHLY]: 12,
  [PayFrequency.ANNUALLY]: 1,
  [PayFrequency.DTY]: 0,
};

export const frequencyLabels: FrequencyLabels = {
  [PayFrequency.WEEKLY]: msg`Weekly`,
  [PayFrequency.WEEKLY48]: msg`Weekly (48 pay periods)`,
  [PayFrequency.BIWEEKLY]: msg`Bi-weekly`,
  // [PayFrequency.BIWEEKLY24]: msg`Bi-weekly (2x month)`,
  [PayFrequency.SEMIMONTHLY]: msg`Semi-monthly`,
  [PayFrequency.MONTHLY]: msg`Monthly`,
  [PayFrequency.ANNUALLY]: msg`Yearly`,
  [PayFrequency.DTY]: msg`Rest of Plan Year`,
};

export const nextDollarFrequencyLabels: FrequencyLabels = {
  ...frequencyLabels,
  [PayFrequency.DTY]: msg`Rest of Year`,
};

//  Fix 200

export const frequencyDescriptions: FrequencyDescriptions = {
  [PayFrequency.WEEKLY]: msg`Weekly (52x per year)`,
  [PayFrequency.WEEKLY48]: msg`Weekly (48x per year)`,
  [PayFrequency.BIWEEKLY]: msg`Bi-weekly (Every other week, 26x per year)`,
  // [PayFrequency.BIWEEKLY24]: 'Bi-weekly (Twice a month, 24x per year)',
  [PayFrequency.SEMIMONTHLY]: msg`Semi-monthly (Twice a month, 24x per year)`,
  [PayFrequency.MONTHLY]: msg`Monthly (End of the month, 12x per year)`,
  [PayFrequency.ANNUALLY]: msg`Yearly`,
  [PayFrequency.DTY]: msg`Rest of Year`,
};

export const payFrequencyOptions = [
  {
    value: PayFrequency.WEEKLY,
    label: frequencyDescriptions[PayFrequency.WEEKLY],
  },
  // {
  //   value: PayFrequency.WEEKLY48,
  //   label: frequencyDescriptions[PayFrequency.WEEKLY48],
  // },
  {
    value: PayFrequency.BIWEEKLY,
    label: frequencyDescriptions[PayFrequency.BIWEEKLY],
  },
  // {
  //   value: PayFrequency.BIWEEKLY24,
  //   label: frequencyDescriptions[PayFrequency.BIWEEKLY24],
  // },
  {
    value: PayFrequency.SEMIMONTHLY,
    label: frequencyDescriptions[PayFrequency.SEMIMONTHLY],
  },
  {
    value: PayFrequency.MONTHLY,
    label: frequencyDescriptions[PayFrequency.MONTHLY],
  },
];

export type BenefitItemUnion =
  | BenefitMedicalItem
  | BenefitDentalItem
  | BenefitVisionItem
  | BenefitHSAItem
  | BenefitFSAItem
  | BenefitLPFSAItem
  | BenefitHRAItem
  | BenefitAccidentItem
  | BenefitHospitalItem
  | BenefitIllnessItem;

export type KeysOfUnion<T> = T extends T ? keyof T : never;

export type SerializedStore = {
  household: Household;
  healthcare: Dictionary<PersonHealthcare>;
  benefits?: any;
  auth?: AuthState;
  bundles?: Bundles;
  emergency_fund_goal: EmergencyFundType;
  retirement_goal: RetirementGoal;
  retirement?: RetirementState;
  budgeting: BudgetingType;
  settings: SettingsState;
  employer?: EmployerDataResponse;
  people: any;
  incomes: any;
  tobaccoUse: any;
  benefitUses: Dictionary<BenefitUses>;
};

export type SelectSmartData = {
  eligibility: any;
  employer_id: string | null;
  healthcare: Dictionary<PersonHealthcare>;
  people: Dictionary<Person>;
  tobacco_use: Dictionary<TobaccoUse>;
  incomes: Dictionary<PersonIncome>;
  id: string;
  plans: any;
  benefits: any;
  benefit_uses: Dictionary<BenefitUses>;
  nextdollar_id?: string;
  last_location: string | null;
  disclosure_accepted_at: string | null;
  max_progress: number;
  state_of_residence: StatesOfResidence;
  external_ids: Dictionary<string>;
  bundles: Bundles;
  goals: Dictionary<any>;
  accounts: Dictionary<Account>;
  employer: EmployerDataResponse;
  zip_code: string | null;
  consider_spousal_benefits: boolean;
  consider_split_benefits: boolean;
  guidance_mode: string;
  guidance_mode_completed_at: string | null;
  guidance_mode_started_at: string | null;
  available_guidance_modes: string[];
  created_at: string;
  updated_at: string;
};

export type EmployerDataResponse = {
  employer_id: string;
  plan_year: number;
  plan_year_label?: string;
  start_month: number;
  enrollment_url?: string;
  enrollment_end_date?: string;
  enrollment_start_date?: string;
  pay_frequencies: PayFrequency[] | null;
  benadmin_url?: string | null;
  benadmin_name?: string | null;
  retirementplan_name?: string | null;
  retirementplan_url?: string | null;
  emergencyfund_url?: string;
  emergencyfund_name?: string;
  signin_url: string | null;
  signin_name: string | null;
  signin_note: string | null;
  benefits_disclosure?: string;
  default_state_of_residence: StatesOfResidence;
  enable_spousal_benefits: boolean;
  has_domestic_partner_benefits: boolean;
  domestic_partner_disclosure?: string;
  has_tobacco_use: boolean;
  segmentation: BenefitSegmentQuestion[];
  enabled_integrations: Dictionary<string> | null;
  created_at: string;
  updated_at: string;
  employer_name: string;
  employer_description: string;
  employer_logo_url: string;
  custom_content?: object;
};

export type FormikValues<T> = FormikState<T> & {
  isValid: boolean;
} & FormikHelpers<T>;

export type StateInitialized = {
  initialized: boolean;
};

export type StatesOfResidence = keyof typeof statesList;
