/* eslint-disable lingui/no-unlocalized-strings */
/*
    A little form library built on top of react-hook-form and yup.
*/
import React, {
  useMemo,
  useContext,
  useState,
  useEffect,
  useCallback,
  useRef,
  PropsWithChildren,
} from 'react';
import {
  useForm,
  useFormState,
  FieldError,
  Control,
  UseFormReturn,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ObjectSchema } from 'yup';
import { get, set } from 'lodash';

// re-export react-hook-form, especially convenient for Control type
// and Controller component used in writing field components
export * from 'react-hook-form';

function schemaWithDefaultLabels<
  T extends object | null | undefined = object | undefined,
  C = object,
  // @ts-ignore
>(schema: ObjectSchema<T, C>) {
  // @ts-ignore
  for (const [fieldName, fieldSchema] of Object.entries(schema.fields)) {
    // @ts-ignore
    if (!fieldSchema._label) {
      // @ts-ignore
      schema.fields[fieldName] = fieldSchema.label(`This ${fieldSchema.type}`);
    }
  }
  return schema;
}

// custom error message that can be thrown by onSubmit to indicate that the
// submit failed because it was invalid. typically, this would be used when
// a backend request fails even after the front-end validation is successful
export class ValidationError extends Error {
  fields: { [n: string]: string };
  type: string;

  constructor(
    message: string,
    fields: { [n: string]: string } = {},
    type = 'server',
  ) {
    super(message);
    this.name = 'ValidationError';
    this.fields = fields;
    this.type = type;

    // maintain proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ValidationError);
    }
  }
}

type FormState = {
  isDirty: boolean;
  isValid: boolean;
  isSubmitting: boolean;
  isSubmitSuccessful: boolean;
};

interface FormManagerContextProps {
  setFormState: (formRef: any, formInfo: FormState) => void;
  removeForm: (formRef: any) => void;
}

const FormManagerContext = React.createContext<
  FormManagerContextProps | undefined
>(undefined);

export const FormManager = ({
  renderChildren,
}: {
  renderChildren: ({
    submit,
    isValid,
    isDirty,
    isSubmitting,
    isSubmitSuccessful,
    triggerFormValidation,
  }: {
    submit: () => Promise<void>;
    isValid: boolean;
    isDirty: boolean;
    isSubmitting: boolean;
    isSubmitSuccessful: boolean;
    triggerFormValidation: () => Promise<boolean>;
  }) => React.ReactNode;
}) => {
  const [formStates, setFormStates] = useState(new Map());
  const setFormState = useCallback(
    (formRef: any, formInfo: FormState) => {
      if (
        !formStates.has(formRef) ||
        formInfo.isDirty !== formStates.get(formRef).isDirty ||
        formInfo.isValid !== formStates.get(formRef).isValid ||
        formInfo.isSubmitting !== formStates.get(formRef).isSubmitting ||
        formInfo.isSubmitSuccessful !==
          formStates.get(formRef).isSubmitSuccessful
      ) {
        setFormStates(new Map([...formStates, [formRef, formInfo]]));
      }
    },
    [formStates, setFormStates],
  );
  const removeForm = useCallback(
    (formRef: any) => {
      setFormStates(
        (s) =>
          new Map(Array.from(s.entries()).filter(([key]) => key !== formRef)),
      );
    },
    [setFormStates],
  );

  const submit = useCallback(async () => {
    for (const [formRef, formInfo] of formStates.entries()) {
      // submit each form in sequence, one form at a time
      // throw/abort on first exception
      await formRef.current.submit({
        isDirty: formInfo.isDirty,
      });
    }
  }, [formStates]);

  const isValid = useMemo(
    () =>
      Array.from(formStates.values()).every(
        (formInfo: FormState) => formInfo.isValid,
      ),
    [formStates],
  );

  const isDirty = useMemo(
    () =>
      Array.from(formStates.values()).some(
        (formInfo: FormState) => formInfo.isDirty,
      ),
    [formStates],
  );

  const isSubmitting = useMemo(
    () =>
      Array.from(formStates.values()).some(
        (formInfo: FormState) => formInfo.isSubmitting,
      ),
    [formStates],
  );

  const isSubmitSuccessful = useMemo(
    () =>
      Array.from(formStates.values()).some(
        (formInfo: FormState) => formInfo.isSubmitSuccessful,
      ),
    [formStates],
  );

  const triggerFormValidation = useCallback(async () => {
    const isValid = (
      await Promise.all(
        Array.from(formStates.keys()).map((ref) => ref.current.validateForm()),
      )
    ).every((result) => result);
    return isValid;
  }, [formStates]);

  return (
    <FormManagerContext.Provider value={{ setFormState, removeForm }}>
      {renderChildren({
        submit,
        isValid,
        isDirty,
        isSubmitting,
        isSubmitSuccessful,
        triggerFormValidation,
      })}
    </FormManagerContext.Provider>
  );
};

export type Form = Omit<UseFormReturn, 'handleSubmit'> & {
  submit: () => Promise<void>;
};

export type FormOptions = {
  validateDefaultValues?: boolean;
  // These options control which fields are submitted.
  // all - all form fields are submitted
  // dirty-only - only fields that have been modified are submitted
  // root-level-dirty - only submits top level fields/objects that are dirty,
  //       but will ignore dirty flags within the object. i.e. full object is submitted
  //       if anything within it is dirty
  dirtySubmissionMode?: 'all' | 'dirty-only' | 'root-level-dirty';
  mode?: 'onSubmit' | 'onChange' | 'onBlur' | 'onTouched' | 'all';
};

const dirtyFieldsToPropsList = (path: string[], root: any): any => {
  return Object.keys(root).reduce((accum: string[], key) => {
    if (typeof root[key] === 'object') {
      return [...accum, ...dirtyFieldsToPropsList([...path, key], root[key])];
    } else if (root[key] === true) {
      return [...accum, [...path, key].join('.')];
    }
    return accum;
  }, []);
};

export function useFormalist(
  schema: any,
  defaultValues: any,
  onSubmit: (data: any) => Promise<void>,
  {
    validateDefaultValues = true,
    dirtySubmissionMode = 'root-level-dirty',
    mode = 'onChange',
  }: FormOptions = {},
) {
  const formRef = useRef({
    submit: ({ isDirty }: { isDirty: boolean }) =>
      new Promise(() => (isDirty ? undefined : undefined)),
    validateForm: async () => false,
  });

  const formSetContext = useContext(FormManagerContext);

  const resolver = useMemo(
    () => (schema ? yupResolver(schemaWithDefaultLabels(schema)) : undefined),
    [schema],
  );
  const { handleSubmit, ...form } = useForm({
    resolver,
    mode,
    delayError: 500,
    defaultValues: validateDefaultValues
      ? schema.validateSync(defaultValues, { stripUnknown: true })
      : defaultValues,
  });

  const { isValid, isDirty, isSubmitting, isSubmitSuccessful, dirtyFields } =
    useFormState({
      control: form.control,
    });

  const triggerValidation = form.trigger;

  const submit = useCallback(async () => {
    if (isDirty) {
      try {
        await handleSubmit((data) => {
          if (dirtySubmissionMode === 'dirty-only') {
            const dirtyFieldsList = dirtyFieldsToPropsList([], dirtyFields);
            data = dirtyFieldsList.reduce(
              (obj: { [k: string]: any }, key: string) => {
                set(obj, key, get(data, key));
                return obj;
              },
              {},
            );
          } else if (dirtySubmissionMode === 'root-level-dirty') {
            data = Object.keys(dirtyFields).reduce(
              (obj: { [k: string]: any }, key: string) => {
                obj[key] = data[key];
                return obj;
              },
              {},
            );
          }
          return onSubmit(data).then(() => form.reset(() => data));
        })();
      } catch (error) {
        if (error instanceof ValidationError) {
          // special handling of ValidationError to allow onSubmit to report
          // async validation errors like from a server response. this requires
          // cooperation from call site to check the response and to prevent
          // default action, for example
          const error_type = error.type;
          Object.entries(error.fields).forEach(([name, message]) =>
            form.setError(name, { type: error_type, message }),
          );
          throw error;
        } else {
          // all other errors bubble up
          throw error;
        }
      }
    }
  }, [
    handleSubmit,
    onSubmit,
    dirtyFields,
    form,
    dirtySubmissionMode,
    form.setError,
  ]);

  useEffect(() => {
    formSetContext?.setFormState(formRef, {
      isValid,
      isDirty,
      isSubmitting,
      isSubmitSuccessful,
    });
    return () => {
      formSetContext?.removeForm(formRef);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formRef]); // do not include formSetContext

  useEffect(() => {
    formRef.current.submit = submit;
    formRef.current.validateForm = triggerValidation;
    formSetContext?.setFormState(formRef, {
      isValid,
      isDirty,
      isSubmitting,
      isSubmitSuccessful,
    });
  }, [
    triggerValidation,
    submit,
    isValid,
    isDirty,
    isSubmitting,
    isSubmitSuccessful,
    formSetContext?.setFormState,
  ]);

  return { ...form, submit };
}

interface FormControlContextProps {
  control?: Control;
  testID?: string | undefined;
}

const FormControlContext = React.createContext<FormControlContextProps>({});

export const Form: React.FC<PropsWithChildren<FormControlContextProps>> = ({
  control,
  testID,
  children,
}) => (
  <FormControlContext.Provider value={{ control, testID }}>
    {children}
  </FormControlContext.Provider>
);

export function useFormField(
  name: string,
  control: Control | undefined = undefined,
): {
  control: Control;
  error: FieldError | undefined;
  testID: string;
} {
  const controlContext = useContext(FormControlContext);
  const fieldControl = control || controlContext.control;
  const { errors } = useFormState({ control: fieldControl, name });
  if (!fieldControl)
    throw Error('no FormFields parent or control argument to useFormField');
  const testID = controlContext.testID
    ? `${controlContext.testID}-${name}`
    : name;

  const error = get(errors, name) as FieldError | undefined;
  return { control: fieldControl, error, testID };
}

/*
Here's an example of how to write an input field component:

export const TextInputField: React.FC<{
  name: string;
  control?: Control | undefined;
  [key: string]: any;
}> = ({ name, control, ...inputProps }) => {
  const field = useFormField(name, control);
  return (
    <Controller // see react-hook-form
      control={field.control}
      render={({
        field: { onChange, onBlur, value },
        formState: { isSubmitting },
      }) => (
        <TextInput
          {...{
            testID: field.testID,
            error: field.error?.message,
            disabled: isSubmitting,
            ...inputProps,
          }}
          onChange={onChange}
          onBlur={onBlur}
          value={`${value}`}
        />
      )}
      name={name}
    />
  );
};
*/
