import envConfig from './env.config.js';

export const { SITE, DEPLOY_ENV, SETTINGS, SENTRY } = envConfig;

export const site = SITE.ID;
export const defaultSupportEmail = SITE.EMAIL_SUPPORT;
export const variantId = String(SITE.ID);
export const environment = `${SITE.ID}-${DEPLOY_ENV}`;

export default {
  ...envConfig,
  site,
  defaultSupportEmail,
  variantId,
  environment,
};
