/* eslint-disable lingui/no-unlocalized-strings */
import './types';
import {
  getSessionTokenData,
  saveSessionTokenInStorage,
} from '../services/auth';
import { ValidationError } from '../formalist';
import { SITE } from '../../site.config';
import * as types from './types';

import { loadDebugOptions } from 'hooks/useDebug';
import { getTestHeaders } from 'helpers/tests';
export type ApiOptions = {
  includeAuthorization?: boolean;
  throwError?: boolean;
  // Return null on 404 instead of throwing an error
  nullOn404?: boolean;
};

export class ApiError extends Error {
  status: number;
  type: string;
  fields: { [n: string]: string };

  constructor(status: number, error: types.ApiErrorResponse, ...params: any[]) {
    // pass remaining arguments (including vendor specific ones) to parent constructor
    super(...params);

    // maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ApiError);
    }

    this.name = 'ApiError';
    this.message = error?.message || `Network Error: ${status}`;

    // custom error information
    this.status = status;
    this.type = error.type;
    this.fields = error.fields;
  }
}

const onSessionDataSetEventName = 'savviApi-sessionDataSet';

export class Api {
  sessionData: any;
  onSessionDataSet: Event;

  constructor() {
    this.onSessionDataSet = new Event(onSessionDataSetEventName);
    getSessionTokenData()
      .then((sessionData) => this.setSession(sessionData))
      .catch(() => undefined);
  }

  setSession = (sessionData: any) => {
    this.sessionData = sessionData;
    window.dispatchEvent(this.onSessionDataSet);
  };

  addOnSessionDataSetListener = (handler: (e: Event) => void) => {
    return window.addEventListener(onSessionDataSetEventName, handler);
  };

  removeOnSessionDataSetListener = (handler: (e: Event) => void) => {
    return window.removeEventListener(onSessionDataSetEventName, handler);
  };

  getSession = () => {
    return this.sessionData;
  };

  injectSession = async (sessionData: any) => {
    saveSessionTokenInStorage(sessionData);
    this.setSession(sessionData);
  };

  request = async (
    method: string,
    endpoint: string,
    data: any = undefined,
    {
      includeAuthorization = true,
      throwError = true,
      // Return null on 404 instead of throwing an error
      nullOn404 = false,
    }: ApiOptions = {},
  ): Promise<any> => {
    const headers: { [name: string]: string } = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };

    // Custom x-on-date header
    const debugOptions = loadDebugOptions();
    if (
      debugOptions &&
      !!debugOptions.customPostDate &&
      debugOptions.customPostDateEnabled
    ) {
      headers['X-On-Date'] = debugOptions.customPostDate;
    }

    if (includeAuthorization) {
      const sessionToken = this.sessionData?.session_token;
      if (sessionToken) {
        headers['Authorization'] = `Bearer ${sessionToken}`;
      }
    }

    const testId = await getTestHeaders(data);
    if (testId) {
      headers['X-Test-Id'] = testId;
    }

    const options: { [name: string]: any } = { method, headers };

    if (data && method != 'GET') {
      options['body'] = JSON.stringify(data);
    }

    const url = !endpoint.startsWith('http')
      ? `${SITE.API_URL}/${endpoint}`
      : endpoint;

    const response = await fetch(url, options);

    const responseData = await response.json();

    // Unauthorized attempt
    if (response.status === 401) {
      // There is a race condition where this puts us in an infinite
      // redirect loop when the api request happens before auth has succeeded
      //window.location.assign(`/${RouteNames.Welcome}`);
      return;
    }

    if (response.status === 404 && nullOn404) {
      return null;
    }

    if (response.status != 200 && throwError) {
      if (responseData?.error.type == 'SchemaValidationError') {
        // throw a special error so our form library can treat it as an
        // async validation error
        throw new ValidationError(
          responseData?.error?.message,
          responseData?.error?.fields,
        );
      } else {
        // eslint-disable-next-line no-console
        console.log(responseData);

        throw new ApiError(response.status, responseData?.error);
      }
    }

    return responseData;
  };

  get = async (
    endpoint: string,
    {
      includeAuthorization = true,
      throwError = true,
      nullOn404 = false,
    }: ApiOptions = {},
  ): Promise<any> => {
    return await this.request('GET', endpoint, undefined, {
      includeAuthorization,
      throwError,
      nullOn404,
    });
  };

  post = async (
    endpoint: string,
    data: any = {},
    {
      includeAuthorization = true,
      throwError = true,
      nullOn404 = false,
    }: ApiOptions = {},
  ): Promise<any> => {
    return await this.request('POST', endpoint, data, {
      includeAuthorization,
      throwError,
      nullOn404,
    });
  };

  put = async (
    endpoint: string,
    data: any = {},
    {
      includeAuthorization = true,
      throwError = true,
      nullOn404 = false,
    }: ApiOptions = {},
  ): Promise<any> => {
    return await this.request('PUT', endpoint, data, {
      includeAuthorization,
      throwError,
      nullOn404,
    });
  };

  patch = async (
    endpoint: string,
    data: any = undefined,
    {
      includeAuthorization = true,
      throwError = true,
      nullOn404 = false,
    }: ApiOptions = {},
  ): Promise<any> => {
    return await this.request('PATCH', endpoint, data, {
      includeAuthorization,
      throwError,
      nullOn404,
    });
  };

  delete = async (
    endpoint: string,
    {
      includeAuthorization = true,
      throwError = true,
      nullOn404 = false,
    }: ApiOptions = {},
  ): Promise<any> => {
    return await this.request('DELETE', endpoint, undefined, {
      includeAuthorization,
      throwError,
      nullOn404,
    });
  };

  getBundlesSummary = async (
    bundlesId: string,
    bundleKey: string,
  ): Promise<any> => {
    return this.get(
      `selectsmart/public/bundles-summary/${bundlesId}/${bundleKey}`,
      {
        includeAuthorization: false,
      },
    );
  };
}

// create default api singleton we will use except in tests where
// we might want to stub/mock the backend requests
export const api = new Api();
