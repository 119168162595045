import { fromPairs, intersection } from 'lodash';
import {
  BenefitItemType,
  BenefitSegmentQuestionType,
  BenefitsGroupType,
  BenefitValidationItem,
  BenefitValidationValues,
  MedicalPlanKind,
  PlanFrequency,
  BenefitItem,
  Account,
} from '../interfaces/benefits';
import { BenefitItemUnion, Dictionary, Role } from '../interfaces/common';
import { getBenefitCost } from '../components/BenefitCard/helpers';
import { BenefitSegmentQuestion } from '../interfaces/employer';
import { PlanBenefit } from 'selectsmart-api';
import { msg } from '@lingui/macro';
import { MessageDescriptor } from '@lingui/core';
import { AccountMap, PlanMap } from 'selectsmart-api/sdk/selectsmartSchemas';

export const isIndemnity = (type: BenefitItemType) =>
  type === BenefitItemType.ACCIDENT_INDEMNITY ||
  type === BenefitItemType.HOSPITAL_INDEMNITY ||
  type === BenefitItemType.ILLNESS_INDEMNITY;

export const getPlanTypeName = (
  type: BenefitItemType,
  benefit?: BenefitItem,
  abbreviate?: boolean,
) => {
  switch (type) {
    case BenefitItemType.MEDICAL_PLAN:
      return msg`Health Plan`;
    case BenefitItemType.DENTAL_PLAN:
      return msg`Dental Plan`;
    case BenefitItemType.VISION_PLAN:
      return msg`Vision Plan`;
    case BenefitItemType.HSA:
      return abbreviate ? msg`HSA` : msg`Health Savings Account`;
    case BenefitItemType.HRA:
      return abbreviate ? msg`HRA` : msg`Health Reimbursement Arrangement`;
    case BenefitItemType.FSA:
      return abbreviate ? msg`FSA` : msg`Flexible Spending Account`;
    case BenefitItemType.LPFSA:
      return msg`Limited-Purpose FSA`;
    case BenefitItemType.ACCIDENT_INDEMNITY:
      return msg`Accident Insurance`;
    case BenefitItemType.ILLNESS_INDEMNITY:
      // TODO: i18n check this
      // eslint-disable-next-line lingui/no-unlocalized-strings
      if (benefit?.name.includes('Specified Disease')) {
        return msg`Specified Disease Insurance`;
      } else {
        return msg`Critical Illness Insurance`;
      }
    case BenefitItemType.HOSPITAL_INDEMNITY:
      return msg`Hospital Indemnity Insurance‡`;
    default:
      return msg``;
  }
};

export const getPlanTypeShortName = (type: BenefitItemType) => {
  switch (type) {
    case BenefitItemType.MEDICAL_PLAN:
      return msg`Health`;
    case BenefitItemType.DENTAL_PLAN:
      return msg`Dental`;
    case BenefitItemType.VISION_PLAN:
      return msg`Vision`;
    case BenefitItemType.HSA:
      return msg`HSA`;
    case BenefitItemType.HRA:
      return msg`HRA`;
    case BenefitItemType.FSA:
      return msg`FSA`;
    case BenefitItemType.LPFSA:
      return msg`Limited-Purpose FSA`;
    case BenefitItemType.ACCIDENT_INDEMNITY:
      return msg`Accident Insurance`;
    case BenefitItemType.ILLNESS_INDEMNITY:
      return msg`Critical Illness Insurance`;
    case BenefitItemType.HOSPITAL_INDEMNITY:
      return msg`Hospital Indemnity Insurance`;
    default:
      return msg``;
  }
};

export const getPlanNameByType = (type: BenefitItemType) => {
  switch (type) {
    case BenefitItemType.MEDICAL_PLAN:
      return msg`Health Plan`;
    case BenefitItemType.DENTAL_PLAN:
      return msg`Dental Plan`;
    case BenefitItemType.VISION_PLAN:
      return msg`Vision Plan`;
    case BenefitItemType.HEALTH_SAVINGS:
      return msg`Health Savings`;
    case BenefitItemType.ACCIDENT:
    case BenefitItemType.ACCIDENT_INDEMNITY:
      return msg`Accident Insurance`;
    case BenefitItemType.ILLNESS:
    case BenefitItemType.ILLNESS_INDEMNITY:
      return msg`Illness/Disease Insurance`;
    case BenefitItemType.HOSPITAL_INDEMNITY:
      return msg`Hospital Indemnity Insurance`;
    case BenefitItemType.REIMBURSEMENT_HRA:
      return msg`Reimbursement HRA`;
    default:
      return msg``;
  }
};

export const getOptionNameByType = (
  type: BenefitItemType,
  benefitName?: string,
) => {
  switch (type) {
    case BenefitItemType.MEDICAL_PLAN:
      return msg`Health Plan`;
    case BenefitItemType.DENTAL_PLAN:
      return msg`Dental Plan`;
    case BenefitItemType.VISION_PLAN:
      return msg`Vision Plan`;
    case BenefitItemType.HSA:
      return msg`Health Savings Account`;
    case BenefitItemType.HRA:
    case BenefitItemType.REIMBURSEMENT_HRA:
      return msg`Health Reimbursement Arrangement`;

    case BenefitItemType.FSA:
    case BenefitItemType.LPFSA:
    case BenefitItemType.HSA_OR_FSA:
      return msg`Health Account`;
    case BenefitItemType.ILLNESS:
      if (benefitName) {
        // TODO: i18n check this
        // eslint-disable-next-line lingui/no-unlocalized-strings
        if (benefitName.includes('Specified Disease')) {
          return msg`Specified Disease Insurance`;
        } else {
          return msg`Critical Illness Insurance`;
        }
      } else {
        return msg`Illness/Disease Insurance`;
      }
    case BenefitItemType.ACCIDENT:
      return msg`Accident Insurance`;
    case BenefitItemType.HOSPITAL_INDEMNITY:
      return msg`Hospital Indemnity Insurance`;
    case BenefitItemType.LIFE_INSURANCE:
      return msg`Supplemental Life Insurance`;
    case BenefitItemType.BASIC_LIFE_INSURANCE:
      return msg`Basic Life Insurance`;
    case BenefitItemType.STD_INSURANCE:
      return msg`Supplemental Short-Term Disability Insurance`;
    case BenefitItemType.BASIC_STD_INSURANCE:
      return msg`Basic Short-Term Disability Insurance`;
    case BenefitItemType.LTD_INSURANCE:
      return msg`Supplemental Long-Term Disability Insurance`;
    case BenefitItemType.BASIC_LTD_INSURANCE:
      return msg`Basic Long-Term Disability Insurance`;
    case BenefitItemType.ADD_INSURANCE:
      return msg`Supplemental AD&D insurance`;
    case BenefitItemType.BASIC_ADD_INSURANCE:
      return msg`Basic AD&D Insurance`;
    default:
      return msg``;
  }
};

export const getSymbolByType = (type: BenefitItemType) => {
  switch (type) {
    case BenefitItemType.HSA:
      return '⚘';
    case BenefitItemType.HRA:
      return '⚘';
    case BenefitItemType.ILLNESS:
      return '‡';
    case BenefitItemType.ACCIDENT:
      return '‡';
    case BenefitItemType.HOSPITAL_INDEMNITY:
      return '‡';
    default:
      return '';
  }
};

export const calculateValueByPeriod = (
  period: PlanFrequency | string,
  value: number,
) => {
  switch (period) {
    case PlanFrequency.WEEKLY:
      return value * 52;
    case PlanFrequency.BIWEEKLY:
      return (value * 52) / 2;
    case PlanFrequency.MONTHLY:
      return value * 12;
    case PlanFrequency.SEMIMONTHLY:
      return value * 24;
    case PlanFrequency.ANNUALLY:
      return value;
    default:
      return value * parseInt(period);
  }
};

export const getGroupTypeByBenefitType = (benefitType?: BenefitItemType) => {
  if (!benefitType) {
    return BenefitsGroupType.SUPPLEMENTAL_HEALTH;
  }

  switch (benefitType) {
    case BenefitItemType.MEDICAL_PLAN:
      return BenefitsGroupType.MEDICAL_PLANS;
    case BenefitItemType.DENTAL_PLAN:
      return BenefitsGroupType.DENTAL_PLANS;
    case BenefitItemType.VISION_PLAN:
      return BenefitsGroupType.VISION_PLANS;
    case BenefitItemType.HRA:
    case BenefitItemType.FSA:
    case BenefitItemType.HSA:
    case BenefitItemType.LPFSA: {
      return BenefitsGroupType.HEALTH_ACCOUNTS;
    }
    default:
      return BenefitsGroupType.SUPPLEMENTAL_HEALTH;
  }
};

const groupBenefits = (plans: BenefitValidationValues) =>
  plans.reduce((grouped, item) => {
    const groupType = getGroupTypeByBenefitType(item.type);
    if (grouped[groupType]) {
      grouped[groupType].push(item);
    } else {
      grouped[groupType] = [item];
    }

    return grouped;
  }, {} as Dictionary<BenefitValidationValues>);

const sortableBenefitGroups = [
  BenefitsGroupType.MEDICAL_PLANS,
  BenefitsGroupType.DENTAL_PLANS,
  BenefitsGroupType.VISION_PLANS,
  BenefitsGroupType.SUPPLEMENTAL_HEALTH,
];

const sortGroupedBenefits = (
  groupedPlans: Dictionary<BenefitValidationValues>,
): Dictionary<BenefitValidationValues> => {
  sortableBenefitGroups.forEach((groupType) => {
    const group = groupedPlans[groupType];
    if (group) {
      group.sort((a, b) => getBenefitCost(a) - getBenefitCost(b));
    }
  });

  return groupedPlans;
};

export const excludedPlanKeys = (type: BenefitItemType) => {
  switch (type) {
    case BenefitItemType.MEDICAL_PLAN:
    case BenefitItemType.VISION_PLAN:
    case BenefitItemType.ILLNESS_INDEMNITY:
    case BenefitItemType.ACCIDENT_INDEMNITY:
    case BenefitItemType.HOSPITAL_INDEMNITY:
    case BenefitItemType.DENTAL_PLAN: {
      return ['premium', 'premium_frequency'];
    }
    case BenefitItemType.HSA:
    case BenefitItemType.FSA:
    case BenefitItemType.LPFSA: {
      return ['contribution_frequency', 'employer_contribution'];
    }
    case BenefitItemType.HRA: {
      return [
        'contribution_frequency',
        'employer_contribution',
        'uncovered_amount',
      ];
    }
    default: {
      return [];
    }
  }
};

export const excludedBenefitKeys = (type: BenefitItemType) => {
  switch (type) {
    case BenefitItemType.MEDICAL_PLAN: {
      return [
        'in_network',
        'kind',
        'name',
        'is_hdhp',
        'id',
        'contribution_frequency',
        'employer_contribution',
      ];
    }
    case BenefitItemType.DENTAL_PLAN:
    case BenefitItemType.VISION_PLAN: {
      return [
        'in_network',
        'name',
        'kind',
        'id',
        'contribution_frequency',
        'employer_contribution',
      ];
    }
    case BenefitItemType.HSA:
    case BenefitItemType.FSA:
    case BenefitItemType.LPFSA:
    case BenefitItemType.HRA:
      return ['name', 'id', 'premium', 'premium_frequency'];
    case BenefitItemType.ACCIDENT_INDEMNITY:
    case BenefitItemType.ILLNESS_INDEMNITY:
    case BenefitItemType.HOSPITAL_INDEMNITY: {
      return ['name', 'id'];
    }
    default: {
      return [];
    }
  }
};

export const mapBenefitsToFormValues = (
  benefitsMap: Dictionary<BenefitItemUnion>,
  plans: Dictionary<BenefitItem>,
  role: Role,
): Dictionary<BenefitValidationValues> => {
  // Form UI values
  const benefitCards = Object.values(benefitsMap)
    .filter((item) => item)
    .filter(
      (item) =>
        (item?.employer_id === null && role === Role.SPOUSE) ||
        (item?.employer_id !== null && role === Role.CLIENT),
    )
    .map((item) => {
      if (!plans[item.plan_id] || !item) {
        return {};
      }

      const commonValues = {
        id: item.id,
        name: plans[item.plan_id].name || '',
      };

      switch (item.type) {
        case BenefitItemType.MEDICAL_PLAN: {
          // Local

          const plan = plans[item.plan_id];

          const fields = {
            ...commonValues,

            // From plan
            kind: plan.kind || '',
            copay: plan.in_network?.copay,
            coinsurance: plan.in_network?.coinsurance,
            deductibles_individual: plan.in_network?.deductibles?.individual,
            deductibles_family: plan.in_network?.deductibles?.family,
            oop_individual: plan.in_network?.out_of_pocket_limits?.individual,
            oop_family: plan.in_network?.out_of_pocket_limits?.family,
            is_hdhp: plan.is_hdhp,
            rx_copay: plan.services?.drugs_generic?.in_network?.copay || null,
            rx_coinsurance:
              plan.services?.drugs_generic?.in_network?.coinsurance || null,
            services: plan.services,

            // From benefit
            premium_frequency: item.premium_frequency,
            type: item.type,
            premiums_individual: item?.premiums?.individual,
            premiums_plus_spouse: item?.premiums?.plus_spouse,
            premiums_plus_children: item?.premiums?.plus_children,
            premiums_family: item?.premiums?.family,
          };

          // Add fields for services
          fields.services !== null &&
            Object.keys(fields.services).map((row) => {
              // @ts-ignore
              fields[`service_${row}_copay`] =
                // @ts-ignore
                fields.services[row]?.in_network?.copay;
              // @ts-ignore
              fields[`service_${row}_coinsurance`] =
                // @ts-ignore
                fields.services[row]?.in_network?.coinsurance;
            });

          return fields;
        }

        case BenefitItemType.DENTAL_PLAN: {
          // Local
          const plan = plans[item.plan_id];

          const fields = {
            ...commonValues,

            type: item.type,
            premiums_individual: item?.premiums?.individual,
            premiums_plus_spouse: item?.premiums?.plus_spouse,
            premiums_plus_children: item?.premiums?.plus_children,
            premiums_family: item?.premiums?.family,
            premium_frequency: item.premium_frequency,

            copay: plan.in_network?.copay,
            coinsurance: plan.in_network?.coinsurance,

            deductibles_individual: plan.in_network?.deductibles?.individual,
            annual_limit_individual: plan.in_network?.annual_limits?.individual,
            has_preventive_care: plan.in_network.has_preventive_care || false,
            services: plan.services,

            ortho_coinsurance:
              plan?.services?.orthodontics?.in_network?.coinsurance || null,

            ortho_limit:
              plan?.in_network?.specific_annual_limits?.orthodontics
                ?.individual || null,
          };

          return fields;
        }

        case BenefitItemType.VISION_PLAN: {
          // Local
          const plan = plans[item.plan_id];

          const fields = {
            ...commonValues,

            // From benefit
            type: item.type,
            premiums_individual: item?.premiums?.individual,
            premiums_plus_spouse: item?.premiums?.plus_spouse,
            premiums_plus_children: item?.premiums?.plus_children,
            premiums_family: item?.premiums?.family,
            premium_frequency: item.premium_frequency,

            // Services
            services: plan.services,
          };

          // Add fields for services
          Object.keys(fields.services).map((row) => {
            // @ts-ignore
            fields[`service_${row}_copay`] =
              // @ts-ignore
              fields.services[row]?.in_network?.copay;
            // @ts-ignore
            fields[`service_${row}_coinsurance`] =
              // @ts-ignore
              fields.services[row]?.in_network?.coinsurance;
            // @ts-ignore
            fields[`service_${row}_allowance`] =
              // @ts-ignore
              fields.services[row]?.in_network?.allowance;
            // @ts-ignore
            fields[`service_${row}_discount`] =
              // @ts-ignore
              fields.services[row]?.in_network?.discount;
          });

          return fields;
        }

        case BenefitItemType.HSA: {
          return {
            ...commonValues,
            type: item.type,
            contribution_frequency: item.contribution_frequency,
            employer_contributions_individual:
              item?.employer_contributions?.individual,
            employer_contributions_family: item?.employer_contributions?.family,
          };
        }

        case BenefitItemType.LPFSA:
        case BenefitItemType.HRA:
        case BenefitItemType.FSA: {
          return {
            ...commonValues,
            type: item.type,
            employer_contributions_individual:
              item?.employer_contributions?.individual,
            employer_contributions_family: item?.employer_contributions?.family,
            contribution_frequency: item.contribution_frequency,
          };
        }

        case BenefitItemType.ACCIDENT_INDEMNITY:
        case BenefitItemType.ILLNESS_INDEMNITY:
        case BenefitItemType.HOSPITAL_INDEMNITY: {
          return {
            ...commonValues,
            type: item.type,
            premium: item.premium,
            premium_frequency: item.premium_frequency,
          };
        }
      }
    });

  return sortGroupedBenefits(
    groupBenefits(benefitCards as BenefitValidationValues),
  );
};

export const getMedicalPlanDescription = (
  kind: MedicalPlanKind,
  isHighDeductible: boolean,
) => {
  return isHighDeductible
    ? msg`High-deductible ${kind.toUpperCase()}`
    : msg`Traditional ${kind.toUpperCase()}`;
};

export const getHealthAccountDescription = (
  type: BenefitItemType,
  abbreviateName?: boolean,
) => {
  return getPlanTypeName(type, undefined, abbreviateName);
};

export const getBenefitDescription = (
  item: BenefitValidationItem,
  abbreviateName?: boolean,
) => {
  let description: MessageDescriptor;
  switch (item.type) {
    case BenefitItemType.MEDICAL_PLAN:
      description = getMedicalPlanDescription(item.kind, !!item.is_hdhp);
      break;
    case BenefitItemType.HSA:
    case BenefitItemType.FSA:
    case BenefitItemType.LPFSA:
    case BenefitItemType.HRA:
      description = getHealthAccountDescription(item.type, abbreviateName);
      break;
    case BenefitItemType.DENTAL_PLAN:
      description = msg`Dental Plan`;
      break;
    case BenefitItemType.VISION_PLAN:
      description = msg`Vision Plan`;
      break;
    case BenefitItemType.ACCIDENT_INDEMNITY:
    case BenefitItemType.ILLNESS_INDEMNITY:
    case BenefitItemType.HOSPITAL_INDEMNITY:
      description = getPlanTypeName(item.type);
      break;
  }

  return description;
};

export const healthAccountBenefitTypes = [
  BenefitItemType.HSA,
  BenefitItemType.FSA,
  BenefitItemType.LPFSA,
  BenefitItemType.HRA,
];

export const isBenefitHealthAccount = (type?: BenefitItemType) => {
  if (!type) {
    return false;
  }

  return healthAccountBenefitTypes.includes(type);
};

type SegmentsFilterConfig = {
  isMarried?: boolean;
};

//(!question.only_if_married || config.isMarried),

export const filterSegmentsQuestions = (
  segmentsQuestions: BenefitSegmentQuestion[],
  config: SegmentsFilterConfig,
) =>
  segmentsQuestions?.filter
    ? segmentsQuestions.filter((question) => {
        // Not our type
        if (question.type !== BenefitSegmentQuestionType.MULTIPLE_CHOICE) {
          return false;
        }

        // No question, no display.
        if (!question.question) {
          return false;
        }

        // If this property exist, only include if configured to do so
        if (question.only_if_married && !config.isMarried) {
          return false;
        }

        // Return default
        return true;
      })
    : [];

export const getDefaultBenefit = (type: BenefitItemType, planId: string) => {
  let benefit: any = {
    id: `new_benefit_${type}`,
    employer_id: null,
    type,
    plan_id: planId,
  };

  switch (type) {
    case BenefitItemType.MEDICAL_PLAN:
      // Needs to match what the api returns
      benefit = {
        ...benefit,
        plan_year: null,
        household_id: null,
        source: null,
        source_id: null,
        entry_status: null,
        eligibility: null,
        is_spouse_eligible: true,
        are_children_eligible: true,
        is_section125: false,
        created_at: null,
        updated_at: null,
        premium: null,
        premiums: {
          individual: null,
          plus_spouse: null,
          plus_children: null,
          family: null,
        },
        premium_frequency: '',
      };
      break;

    case BenefitItemType.DENTAL_PLAN:
      benefit = {
        ...benefit,
        plan_year: null,
        household_id: null,
        source: null,
        source_id: null,
        entry_status: null,
        eligibility: null,
        is_spouse_eligible: true,
        are_children_eligible: true,
        is_section125: false,
        created_at: null,
        updated_at: null,
        premium: null,
        premiums: {
          individual: null,
          plus_spouse: null,
          plus_children: null,
          family: null,
        },
        premium_frequency: '',
      };
      break;

    case BenefitItemType.VISION_PLAN:
      benefit = {
        ...benefit,
        plan_year: null,
        household_id: null,
        source: null,
        source_id: null,
        entry_status: null,
        eligibility: null,
        is_spouse_eligible: true,
        are_children_eligible: true,
        is_section125: false,
        created_at: null,
        updated_at: null,
        premium: null,
        premiums: {
          individual: null,
          plus_spouse: null,
          plus_children: null,
          family: null,
        },
        premium_frequency: '',
        services: {
          routine_eye_care: {
            in_network: {
              copay: null,
              coinsurance: null,
              allowance: null,
              discount: null,
              is_deductible_waived: false,
            },
          },
          glasses: {
            in_network: {
              copay: null,
              coinsurance: null,
              allowance: null,
              discount: null,
              is_deductible_waived: false,
            },
          },
          frames: {
            in_network: {
              copay: null,
              coinsurance: null,
              allowance: null,
              discount: null,
              is_deductible_waived: false,
            },
          },
          vision_procedure: {
            in_network: {
              copay: null,
              coinsurance: null,
              allowance: null,
              discount: null,
              is_deductible_waived: false,
            },
          },
          contact_lens_fitting: {
            in_network: {
              copay: null,
              coinsurance: null,
              allowance: null,
              discount: null,
              is_deductible_waived: false,
            },
          },
          contact_lenses: {
            in_network: {
              copay: null,
              coinsurance: null,
              allowance: null,
              discount: null,
              is_deductible_waived: false,
            },
          },
        },
      };

      break;

    case BenefitItemType.ACCIDENT_INDEMNITY:
    case BenefitItemType.ILLNESS_INDEMNITY:
    case BenefitItemType.HOSPITAL_INDEMNITY:
    case BenefitItemType.LPFSA:
    case BenefitItemType.FSA:
    case BenefitItemType.HRA:
    case BenefitItemType.HSA:
      benefit = {
        ...benefit,
        contribution_frequency: '',
        employer_contributions: {
          individual: null,
          family: null,
        },
      };
      break;

    default:
      benefit = {
        ...benefit,
      };
      break;
  }

  return benefit;
};

export const getDefaultPlan = (type: BenefitItemType) => {
  let plan: any = {
    type,
    id: `new_plan_${type}`,
  };

  switch (type) {
    // Needs to match what the api returns
    case BenefitItemType.MEDICAL_PLAN:
      plan = {
        ...plan,
        employer_id: null,
        household_id: null,
        source: null,
        source_id: null,
        external_ids: null,
        external_aliases: null,
        entry_status: null,
        name: null,
        notes: null,
        info_url: null,
        admin_url: null,
        requires_one_of: null,
        incompatible_with: null,
        created_at: null,
        updated_at: null,
        archived_at: null,
        kind: null,
        is_hdhp: false,
        tobacco_surcharges: {
          family: null,
          subscriber: null,
          spouse: null,
          child: null,
        },
        is_tobacco_surcharge_employee_based: false,
        in_network: {
          copay: null,
          coinsurance: 0,
          has_preventive_care: false,
          deductibles: {
            single: null,
            individual: null,
            family: null,
          },
          is_deductible_embedded: false,
          out_of_pocket_limits: {
            single: null,
            individual: null,
            family: null,
          },
          specific_deductibles: {},
          specific_annual_limits: {
            orthodontics: {
              in_network: {
                individual: null,
              },
            },
          },
          specific_out_of_pocket_limits: {},
        },
        out_of_network: null,
        excluded_services: [],
        services: {
          drugs_generic: {
            in_network: {
              coinsurance: 0,
              copay: null,
              is_deductible_waived: false,
            },
          },
        },
      };
      break;

    case BenefitItemType.DENTAL_PLAN:
      plan = {
        ...plan,
        kind: '',
        in_network: {
          coinsurance: 0,
          copay: null,
          deductibles: {
            individual: null,
            family: null,
          },
          annual_limits: {
            individual: null,
          },
          specific_annual_limits: {
            orthodontics: {
              individual: null,
            },
          },
          has_preventive_care: false,
        },
        services: {
          orthodontics: {
            in_network: {
              coinsurance: null,
            },
          },
        },
      };
      break;

    case BenefitItemType.VISION_PLAN:
      plan = {
        ...plan,
        premium_frequency: PlanFrequency.MONTHLY,
        premiums: {
          individual: null,
          plus_spouse: null,
          plus_children: null,
          family: null,
        },
        services: {
          routine_eye_care: {
            dupe: 'child_eye_exam',
            in_network: {
              copay: null,
              coinsurance: 0,
              allowance: null,
              discount: null,
              is_deductible_waived: false,
            },
          },
          glasses: {
            dupe: 'child_glasses',
            in_network: {
              copay: null,
              coinsurance: 100,
              allowance: null,
              discount: null,
              is_deductible_waived: false,
            },
          },
          frames: {
            dupe: 'child_frames',
            in_network: {
              copay: null,
              coinsurance: 100,
              allowance: null,
              discount: null,
              is_deductible_waived: false,
            },
          },
          vision_procedure: {
            in_network: {
              copay: null,
              coinsurance: 100,
              allowance: null,
              discount: null,
              is_deductible_waived: false,
            },
          },
          contact_lens_fitting: {
            in_network: {
              copay: null,
              coinsurance: 100,
              allowance: null,
              discount: null,
              is_deductible_waived: false,
            },
          },
          contact_lenses: {
            in_network: {
              copay: null,
              coinsurance: 100,
              allowance: null,
              discount: null,
              is_deductible_waived: false,
            },
          },
        },
      };
      break;

    case BenefitItemType.LPFSA:
    case BenefitItemType.FSA:
    case BenefitItemType.HRA:
    case BenefitItemType.HSA:
    case BenefitItemType.ACCIDENT_INDEMNITY:
    case BenefitItemType.ILLNESS_INDEMNITY:
    case BenefitItemType.HOSPITAL_INDEMNITY:
      plan = {
        ...plan,
        contribution_frequency: PlanFrequency.ANNUALLY,
        employer_contributions: {
          individual: null,
          family: null,
        },
      };
      break;
  }
  return plan;
};

export function adminUrlsByPlan(
  accounts?: Dictionary<Account> | AccountMap,
  plans?: Dictionary<PlanBenefit> | PlanMap,
) {
  if (!plans || !accounts) return {} as Dictionary<string>;
  return fromPairs(
    Object.values(accounts)
      .filter((a) => !!a && a.plan_id)
      .map((a) => [a.plan_id, plans[a.plan_id]?.admin_url]),
  );
}

export const checkAvailableProtection = (benefitTypes: string[]) => {
  return {
    hasLife:
      intersection(
        [BenefitItemType.BASIC_LIFE_INSURANCE, BenefitItemType.LIFE_INSURANCE],
        benefitTypes,
      ).length > 0,
    hasADD:
      intersection(
        [BenefitItemType.ADD_INSURANCE, BenefitItemType.BASIC_ADD_INSURANCE],
        benefitTypes,
      ).length > 0,

    hasSTD:
      intersection(
        [BenefitItemType.BASIC_STD_INSURANCE, BenefitItemType.STD_INSURANCE],
        benefitTypes,
      ).length > 0,
    hasLTD:
      intersection(
        [BenefitItemType.BASIC_LTD_INSURANCE, BenefitItemType.LTD_INSURANCE],
        benefitTypes,
      ).length > 0,
  };
};
